<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Sécurité</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="notification is-success" v-if="success !== null">
            {{ success }}
          </div>
          <div class="notification is-danger" v-if="error !== null">
            {{ error }}
          </div>

          <h2>Valider un document</h2>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Document</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <textarea class="input" v-model="document"></textarea>
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Clé</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" v-model="ina" />
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal"></div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <button
                    :class="`button is-primary ${loading ? 'is-loading' : ''}`"
                    @click="submitDocument"
                  >
                    Valider
                  </button>
                </p>
              </div>
            </div>
          </div>

          <h2>Valider une archive</h2>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Archive</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="file"
                    @change="handleFileUpload($event)"
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal"></div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <button
                    :class="`button is-primary ${loading ? 'is-loading' : ''}`"
                    @click="submitArchive"
                  >
                    Valider
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      document: "",
      error: null,
      success: null,
      ina: "",
      archive: null,
    };
  },
  computed: {
    formatDate() {
      return (d) => {
        let da = new Date(d);
        return da.toLocaleDateString("fr-FR");
      };
    },
  },
  methods: {
    handleFileUpload(event) {
      this.archive = event.target.files[0];
    },
    async submitDocument() {
      this.error = null;
      this.success = null;
      const resp = await axios.post(
        this.URL_API_SERVER + "/admin/config/checkDocument",
        {
          document: this.document,
          ina: this.ina.replace(" ", ""),
        }
      );
      if (resp.status === 200 && resp.data.result === "ok") {
        this.success = "Le document n'a pas été altéré.";
      } else {
        this.error = "Le document a été altéré ou la clé est fausse";
      }
    },
    async submitArchive() {
      this.error = null;
      this.success = null;
      let formData = new FormData();

      formData.append("archive", this.archive);

      const resp = await axios.post(
        this.URL_API_SERVER + "/admin/config/checkArchive",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (resp.status === 200 && resp.data.result === "ok") {
        this.success = "Le document n'a pas été altéré.";
      } else {
        this.error = "Le document a été altéré ou la clé est fausse";
      }
    },
  },
  created() {},

  components: {},
};
</script>
