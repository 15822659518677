<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Formules</li>
              <li>
                {{ $route.params.id === "ajouter" ? "Ajouter" : "Modifier" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">
                {{
                  $route.params.id === "ajouter" ? "Ajouter" : "Modifier"
                }}
                une formule
              </h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <form method="get">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Nom</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control is-expanded">
                    <input class="input" type="text" v-model="name" />
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Montant</label>
              </div>
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="field has-addons">
                    <p class="control is-expanded">
                      <input
                        class="input"
                        type="number"
                        min="0"
                        step=".01"
                        v-model="price"
                      />
                    </p>
                    <p class="control">
                      <a class="button is-static"> € </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Nombre d'entrées comprises</label>
              </div>
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="field has-addons">
                    <p class="control is-expanded">
                      <input
                        class="input"
                        type="number"
                        min="0"
                        step="1"
                        v-model="peopleMax"
                      />
                    </p>
                    <p class="control">
                      <a class="button is-static"> personnes </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Produits inclus</label>
              </div>
              <div class="field-body is-flex-direction-column">
                <div :class="`dropdown ${openSearch ? 'is-active' : ''}`">
                  <div class="dropdown-trigger">
                    <div class="field">
                      <p class="control is-expanded has-icons-right">
                        <input
                          class="input"
                          type="search"
                          @focus="openSearch = true"
                          v-model="searchProduct"
                          placeholder="Nom du produit..."
                        />
                        <span class="icon is-small is-right"
                          ><search-icon class="mt-2 mr-1"
                        /></span>
                      </p>
                    </div>
                  </div>
                  <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <span
                        class="dropdown-item"
                        v-for="product in productsList
                          .filter((e) =>
                            e.name
                              .toLowerCase()
                              .includes(searchProduct.toLowerCase())
                          )
                          .slice(0, 5)"
                        @click="addProductIncluded(product)"
                        v-bind:key="product._id"
                        >{{ product.name }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="mt-2">
                  <ul>
                    <li
                      v-for="product in productsIncluded"
                      v-bind:key="product.productId + '' + product.size"
                    >
                      <span style="vertical-align: text-bottom">{{
                        productsList.find((e) => e._id === product.productId)
                          .name
                      }}</span>
                      <div class="ml-3 select is-small">
                        <select v-model="product.size">
                          <option
                            v-for="size in productsList.find(
                              (e) => e._id === product.productId
                            ).sizes"
                            v-bind:key="size.key"
                            :value="size.key"
                          >
                            {{ size.name }}
                          </option>
                        </select>
                      </div>
                      <span
                        class="icon mt-1"
                        @click="
                          productsIncluded = productsIncluded.filter(
                            (e) =>
                              e.productId !== product.productId ||
                              e.size !== product.size
                          )
                        "
                        ><delete-icon
                      /></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Crédit disponible</label>
              </div>
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="field has-addons">
                    <p class="control is-expanded">
                      <input
                        class="input"
                        type="number"
                        min="0"
                        step="1"
                        @focus="openSearch = false"
                        v-model="credit"
                      />
                    </p>
                    <p class="control">
                      <a class="button is-static"> € </a>
                    </p>
                  </div>
                  <div class="help">
                    Ce montant correspond au crédit inclus pour le client. Il
                    pourra choisir des produits d'un montant supérieur et la
                    différence sera automatiquement ajoutée.
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label">
                <!-- Left empty for spacing -->
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="field is-grouped">
                    <div class="control">
                      <button
                        type="submit"
                        @click="submit()"
                        :class="`button is-primary ${
                          loading ? 'is-loading' : ''
                        }`"
                      >
                        <span>Enregistrer</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: true,
      error: null,
      name: "",
      credit: "",
      peopleMax: "",
      price: "",
      productsIncluded: [],
      productsList: [],
      openSearch: false,
      searchProduct: "",
    };
  },
  components: {},
  created() {
    const fetchData = async () => {
      if (this.$route.params.id !== "ajouter") {
        const { data: resp, status } = await axios.get(
          this.URL_API_SERVER + "/restricted/formulas/get",
          { params: { id: this.$route.params.id } }
        );
        if (resp.formula === null || status !== 200) {
          // TODO: Ajouter un message ?
          this.$router.push("/formulas");
        } else {
          this.name = resp.formula.name;
          this.price = resp.formula.price;
          this.peopleMax = resp.formula.peopleMax;
          this.productsIncluded = resp.formula.productsIncluded;
          this.credit = resp.formula.credit;
        }
      }

      // Chargement de la liste des produits :
      this.productsList = (
        await axios.get(this.URL_API_SERVER + "/products/all")
      ).data.products;
      this.loading = false;
    };

    fetchData();
  },
  methods: {
    addProductIncluded(product) {
      this.productsIncluded.push({
        productId: product._id,
        size: product.sizes[0].key,
      });
      this.openSearch = false;
    },
    async submit() {
      if (!this.loading) {
        this.loading = true;
        try {
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/formulas/update",
            {
              id:
                this.$route.params.id === "ajouter"
                  ? undefined
                  : this.$route.params.id,
              name: this.name,
              credit: this.credit,
              peopleMax: this.peopleMax,
              price: this.price,
              productsIncluded: this.productsIncluded,
            }
          );
          if (resp.status === 200) {
            this.$router.push({ path: "/formules" });
          } else {
            // TODO: Handle error
          }
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
        this.loading = false;
      }
    },
    handleImage() {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.image = reader.result;
      };
    },
  },
};
</script>
