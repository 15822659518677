<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Tickets</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card has-table" v-if="bills.length >= 1">
        <div class="card-content">
          <div class="b-table has-pagination">
            <div class="table-wrapper has-mobile-cards">
              <table
                class="table is-fullwidth is-striped is-hoverable is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>Caisse</th>
                    <th>Montant</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="b in bills" v-bind:key="b._id">
                    <td data-label="N°">{{ b._id }}/{{ b.docCount }}</td>
                    <td data-label="Caisse">{{ b.kioskName }}</td>

                    <td data-label="Montant">
                      {{
                        new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(
                          b.payments.reduce(
                            (acc, cur) =>
                              acc +
                              (cur.method !== "change"
                                ? cur.amount
                                : -cur.amount),
                            0
                          )
                        )
                      }}
                      TTC
                    </td>
                    <td data-label="Date">
                      {{ new Date(b.dateCreated).toLocaleDateString() }}
                      {{ new Date(b.dateCreated).toLocaleTimeString() }}
                    </td>
                    <td class="is-actions-cell">
                      <div class="buttons is-right">
                        <router-link
                          :to="{ name: 'ticket', params: { id: b._id } }"
                        >
                          <button
                            class="button is-small is-primary"
                            type="button"
                          >
                            <span class="icon"><eye-icon /></span></button
                        ></router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card has-table" v-if="bills.length === 0">
        <div class="card-content">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <span class="icon is-large"
                  ><i class="mdi mdi-emoticon-sad mdi-48px"></i
                ></span>
              </p>
              <p v-if="!loading">Aucun ticket</p>
              <p v-else>Chargement en cours...</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: true,
      offset: 0,
      bills: [],
    };
  },
  methods: {
    async fetchData() {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/admin/bills/all",
        { params: { offset: this.offset } }
      );
      this.loading = false;
      this.bills = [...this.bills, ...resp.bills];
    },
    scrollLoading() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.offset += 100;
          this.fetchData();
        }
      };
    },
  },
  mounted() {
    this.scrollLoading();
  },
  created() {
    this.fetchData();
  },

  components: {},
};
</script>
