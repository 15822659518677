<template>
  <div>
    <div v-if="showOptions" :class="`modal  is-active`">
      <div class="modal-background" @click="showOptions = false"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ product.name.toUpperCase() }}
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="showOptions = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <div
            v-if="
              typeof product.sizes !== 'undefined' && product.sizes.length > 1
            "
          >
            Quel format ?
            <div class="columns is-centered mt-2">
              <div
                class="column is-narrow has-text-centered"
                v-for="size in product.sizes"
                v-bind:key="size.name"
              >
                <div
                  :class="`box  ${
                    currentSize === size.name ? 'has-background-primary' : ''
                  }`"
                  @click="currentSize = size.name"
                >
                  <div>{{ size.name }}</div>
                  <div>
                    <b class="mt-3">{{
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(
                        size.vats.reduce((acc, cur) => cur.price + acc, 0)
                      )
                    }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="this.cart.state.name === null">
            Quel nom ?
            <input
              :value="name"
              class="input mb-3"
              @input="onInputChange"
              placeholder="Ce nom sera enregistré et imprimé sur la bouteille"
            />
            <SKeyboard
              v-if="config.state.pos.keyboard"
              @onChange="onChange"
              :maxLength="12"
              :input="name"
            />
          </div>
        </section>
        <footer class="modal-card-foot is-flex is-flex-direction-row-reverse">
          <button
            class="button is-success"
            @click="confirm"
            v-if="currentSize !== null"
          >
            <check-icon class="mr-2" />Valider
          </button>
          <div class="has-text-grey" v-else-if="currentSize === null">
            Veuillez selectionner une taille
          </div>
          <div class="has-text-grey" v-else>Veuillez saisir un nom</div>
        </footer>
      </div>
    </div>
    <div class="card product" @click="openModal">
      <div class="card-image has-background-white">
        <div
          style="margin: 0.25rem auto"
          class="is-flex is-justify-content-center is-flex-direction-row"
        >
          <div class="image pl-3 pr-3 is-align-self-center is-relative">
            <img
              :src="product.image"
              style="height: 80px; max-width: 100%; object-fit: contain"
            />
          </div>
        </div>
      </div>
      <div class="card-content pt-0">
        <div class="media mb-1">
          <div class="media-content">
            <p class="has-text-weight-bold mb-0 pb-0">
              {{ product.name }}
              {{ product.sizes.length === 1 ? currentSize : "" }}
            </p>
          </div>
        </div>

        <div class="content is-flex mb-0 is-size-7">
          {{
            (this.product.sizes.length === 1 ? "" : "à partir de + ") +
            new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(minPrice)
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.product:active {
  border: 1px solid green;
}
</style>
<script>
import SKeyboard from "./Keyboard.vue";

export default {
  name: "Product",
  props: ["product"],
  inject: ["cart", "config"],

  components: { SKeyboard },
  data() {
    return {
      showOptions: false,
      currentSize:
        this.product.sizes.length > 1 ? null : this.product.sizes[0].name,
      name: "",
    };
  },
  computed: {
    minPrice() {
      return this.product.sizes.reduce(
        (acc, cur) =>
          cur.vats.reduce((acc, cur) => cur.price + acc, 0) > acc
            ? acc
            : cur.vats.reduce((acc, cur) => cur.price + acc, 0),
        this.product.sizes[0].vats.reduce((acc, cur) => cur.price + acc, 0)
      );
    },
  },
  methods: {
    openModal() {
      this.showOptions = true;
      if (this.cart.state.name === null) {
        this.name = "";
      }
      this.currentSize =
        this.product.sizes.length > 1 ? null : this.product.sizes[0].name;
      if (
        this.currentSize !== null &&
        (this.cart.state.name !== null ||
          this.product.family.printSticker === false)
      ) {
        this.confirm();
      }
    },
    confirm() {
      this.showOptions = false;
      if (this.cart.state.name === null && this.name !== "") {
        this.cart.actions.setName(this.name);
      }

      this.cart.actions.addProduct({
        name: this.product.name + " " + this.currentSize,
        amountIncl: this.product.sizes
          .find((e) => e.name === this.currentSize)
          .vats.reduce((acc, cur) => cur.price + acc, 0),
        amountExcl: this.product.sizes
          .find((e) => e.name === this.currentSize)
          .vats.reduce((acc, cur) => cur.price / (1 + cur.vat / 100) + acc, 0),
        qty: 1,
        vats: this.product.sizes.find((e) => e.name === this.currentSize).vats,
        extra: {
          product: this.product,
          size: this.currentSize,
          family: this.product.family.name,
        },
        options:
          this.cart.state.name !== null
            ? ["Nom : " + this.cart.state.name]
            : [],
      });
    },
    onChange(input) {
      this.name = input;
    },

    onInputChange(input) {
      this.name = input.target.value;
    },
  },
  setup() {},
};
</script>
