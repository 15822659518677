<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Ticket</li>
              <li>Voir</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Visualiser un ticket</h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content" v-if="!loading">
          <div class="" style="max-width: 500px">
            <Printable ref="printable" :hidePrintButton="true" :header="false">
              <div v-html="receipt"></div>
              <hr />
              <div class="tr">
                <b>Nombre de tickets avant enregistrement : </b>
                <div>{{ bill.docCount }}</div>
              </div>
              <div class="tr">
                <b>Ticket précédement enregistré : </b>
                <div>{{ bill.previous }}</div>
              </div>

              <div style="text-align: center; font-size: 11px">
                Clé : {{ bill.ina.replace(/(.{10})/g, "$1 ") }}
              </div>
            </Printable>

            <h2 class="title is-4">QR Codes</h2>
            <h3 class="title is-5">Bouteille / Conso</h3>
            <div class="" v-for="qr in credits" v-bind:key="qr">
              <img :src="qr" />
            </div>

            <h3 class="title is-5">Entrées</h3>
            <div class="" v-for="qr in entries" v-bind:key="qr">
              <img :src="qr" />
            </div>

            <div class="button is-primary mr-2 mt-4" @click="downloadJson">
              Télécharger la source
            </div>
            <button @click="ctc(bill.ina)" class="button mr-2 mt-4">
              Copier la clé
            </button>
            <button @click="print()" class="button mt-4">Imprimer</button>
            <div class="select mt-4">
              <select v-model="kiosk">
                <option v-for="k in kiosks" :value="k._id" v-bind:key="k._id">
                  {{ k.name }}
                </option>
              </select>
            </div>
            <button @click="printKiosk()" class="button mt-4">
              Imprimer sur une borne
            </button>
          </div>
        </div>
        <div class="card-content" v-else>Chargement en cours...</div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Printable from "../components/Printable.vue";
import copy from "copy-to-clipboard";
import QRCode from "qrcode";

export default {
  data() {
    return {
      loading: true,
      bill: null,
      receipt: null,
      entries: [],
      credits: [],
      entriesReceipts: [],
      creditsReceipts: [],
      kiosks: [],
      kioskPrint: null,
    };
  },
  computed: {
    lastPrint() {
      return [...this.bill.prints].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      )[0];
    },
    formatter() {
      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
    },

    formatDate() {
      return (d) => {
        let da = new Date(d);
        return da.toLocaleDateString("fr-FR");
      };
    },
    formatType() {
      return (t) => {
        switch (t) {
          case "daily":
            return "journalière";
          case "monthly":
            return "mensuelle";
          case "yearly":
            return "annuelle";
          case "fiscally":
            return "exercice";
        }
      };
    },
    formatDateT() {
      return (d) => {
        let da = new Date(d);
        return (
          da.toLocaleDateString("fr-FR") + " " + da.toLocaleTimeString("fr-FR")
        );
      };
    },
  },

  components: { Printable },
  created() {
    const fetchData = async () => {
      const { data: resp, status } = await axios.get(
        this.URL_API_SERVER + "/admin/bills/view",
        { params: { id: this.$route.params.id, method: "display" } }
      );
      if (resp.bill === null || status !== 200) {
        // TODO: Ajouter un message ?
        this.$router.push("/tickets");
      } else {
        this.bill = resp.bill;
        this.receipt = resp.receipt;
        this.entries = [];
        this.credits = [];
        this.kiosks = resp.kiosks;
        this.entriesReceipts = resp.entriesReceipts;
        this.creditsReceipts = resp.creditsReceipts;
        for (let index = 0; index < resp.entries.length; index++) {
          this.entries.push(
            await QRCode.toDataURL(resp.entries[index].code, { width: 200 })
          );
        }
        for (let index = 0; index < resp.credits.length; index++) {
          this.credits.push(
            await QRCode.toDataURL(resp.credits[index].code, { width: 200 })
          );
        }
        this.loading = false;
      }
    };

    fetchData();
  },
  methods: {
    async printKiosk() {
      const { data: resp } = await axios.post(
        this.URL_API_SERVER + "/admin/kiosks/routeAction",
        {
          kiosk: this.kiosk,
          action: "print",
          params: {
            print:
              this.receipt +
              "<br />" +
              this.creditsReceipts.join("<br />") +
              "<br />" +
              this.entriesReceipts.join("<br />"),
          },
        }
      );
      if (resp.result !== "ok") {
        alert("erreur de communication");
      }
    },
    async print() {
      const { data: resp, status } = await axios.get(
        this.URL_API_SERVER + "/admin/bills/view",
        { params: { id: this.$route.params.id, method: "print" } }
      );
      if (resp.bill === null || status !== 200) {
        // TODO: Ajouter un message ?
        this.$router.push("/tickets");
      } else {
        this.bill = resp.bill;
        this.receipt = resp.receipt;

        this.$nextTick(() => {
          this.$refs.printable.print();
        });
      }
    },
    ctc(ina) {
      copy(ina);
    },
    downloadJson() {
      const blob = new Blob(
        [JSON.stringify({ ...this.bill, ina: undefined })],
        {
          type: "application/json",
        }
      );
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "label";
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>
