<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Clôture</li>
              <li>Voir</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Visualiser une clôture</h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content" v-if="!loading">
          <div class="" style="max-width: 500px">
            <Printable :header="false">
              <div v-html="receipt"></div>
            </Printable>
            <div class="button is-primary mr-2" @click="downloadJson">
              Télécharger la source
            </div>
            <button @click="ctc(closing.ina)" class="button">
              Copier la clé
            </button>
          </div>
        </div>
        <div class="card-content" v-else>Chargement en cours...</div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Printable from "./../components/Printable.vue";
import copy from "copy-to-clipboard";

export default {
  data() {
    return {
      loading: true,
      closing: null,
      receipt: null,
    };
  },
  computed: {
    formatter() {
      return new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
      });
    },

    formatDate() {
      return (d) => {
        let da = new Date(d);
        return da.toLocaleDateString("fr-FR");
      };
    },
    formatType() {
      return (t) => {
        switch (t) {
          case "daily":
            return "journalière";
          case "monthly":
            return "mensuelle";
          case "yearly":
            return "annuelle";
          case "fiscally":
            return "exercice";
        }
      };
    },
    formatDateT() {
      return (d) => {
        let da = new Date(d);
        return (
          da.toLocaleDateString("fr-FR") + " " + da.toLocaleTimeString("fr-FR")
        );
      };
    },
  },

  components: { Printable },
  created() {
    const fetchData = async () => {
      const { data: resp, status } = await axios.get(
        this.URL_API_SERVER + "/admin/closings/get",
        { params: { id: this.$route.params.id } }
      );
      if (resp.closing === null || status !== 200) {
        // TODO: Ajouter un message ?
        this.$router.push("/clotures");
      } else {
        this.closing = resp.closing;
        this.receipt = resp.receipt;
        this.loading = false;
      }
    };

    fetchData();
  },
  methods: {
    ctc(ina) {
      copy(ina);
    },
    downloadJson() {
      const blob = new Blob(
        [JSON.stringify({ ...this.closing, ina: undefined })],
        {
          type: "application/json",
        }
      );
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "label";
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
};
</script>
