<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Choix de la caisse</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="notification is-info" v-if="3 == 2">
        <b>Nouveauté :</b> Cliquez sur une ligne dans un ticket pour modifier la
        quantité !
      </div>
      <div class="card">
        <div v-if="loading">Chargement en cours...</div>
        <div class="card-content" v-else>
          <div class="is-size-3">Quelle est la caisse ?</div>
          <div class="columns is-multiline">
            <div class="column is-3 p-5" v-for="p in pos" v-bind:key="p._id">
              <router-link
                class="has-icon"
                to="/vente"
                @click="config.actions.setPos(p)"
              >
                <section class="hero is-primary">
                  <div class="hero-body">
                    <p class="title">{{ p.name }}</p>
                  </div>
                </section>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      pos: [],
    };
  },
  computed: {},
  methods: {},
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/admin/pos/all"
      );
      this.pos = resp.pos;

      this.loading = false;
    };
    fetchData();
  },
  inject: ["config"],
  components: {},
};
</script>
