import { reactive, readonly } from "vue";

let state = reactive({
  products: [],
  payments: [],
  name: null,
});
const actions = {
  addProduct: (p) => {
    state.products = [...state.products, p];
  },
  setName: (name) => {
    state.name = name;
  },
  clear: () => {
    state.name = null;
    state.products = [];
    state.payments = [];
  },
  removeProduct: (key) => {
    state.products = [...state.products].filter((e, k) => k !== key);
  },
  discountProduct: (key, percent) => {
    state.products = [...state.products].map((obj, k) =>
      k === key ? { ...obj, discount: percent >= 100 ? 100 : percent } : obj
    );
  },
  changeQty: (key, qty) => {
    state.products = [...state.products].map((obj, k) =>
      k === key ? { ...obj, qty: qty } : obj
    );
  },

  addPayment: (method, amount) => {
    state.payments = [...state.payments, { method, amount }];
  },
  giveChange: (amount) =>
    amount > 0
      ? (state.payments = [
          ...state.payments,
          { method: "change", amount, bill: false },
        ])
      : null,
};

const computed = {
  total: () =>
    state.products.reduce(
      (acc, cur) =>
        acc +
        cur.amountIncl *
          cur.qty *
          (typeof cur.discount === "undefined" ? 1 : 1 - cur.discount / 100),
      0
    ),
  totalPaid: () => state.payments.reduce((acc, cur) => acc + cur.amount, 0),
};
computed.remaining = () => computed.total() - computed.totalPaid();

export default { state: readonly(state), computed, actions };
