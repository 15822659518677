<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Connexion</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Qui êtes vous ?</h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="columns is-multiline">
            <div
              :class="`column is-2 ${
                employeeSelected == employee._id
                  ? 'has-background-grey-lighter'
                  : ''
              }`"
              @click="employeeSelected = employee._id"
              v-for="employee in employees"
              v-bind:key="employee._id"
            >
              <div class="media">
                <div class="media-left">
                  <figure class="image is-64x64">
                    <AccountMultipleOutline :size="64" />
                  </figure>
                </div>
                <div class="media-content is-align-self-center">
                  <p class="title is-5">{{ employee.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Code barre ou mot de passe</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input"
                    type="password"
                    v-model="code"
                    @keyup.enter="submit"
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      type="submit"
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Connexion</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="error !== null"
                class="notification is-danger mt-2"
                style="white-space: pre"
              >
                {{ error }}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import AccountMultipleOutline from "vue-material-design-icons/AccountMultipleOutline.vue";

export default {
  name: "Formula",
  data() {
    return {
      loading: true,
      error: null,
      code: "",
      employees: [],
      employeeSelected: null,
    };
  },
  components: { AccountMultipleOutline },
  inject: ["user"],
  created() {
    const fetchData = async () => {
      const { data: resp, status } = await axios.get(
        this.URL_API_SERVER + "/admin/employees/list"
      );
      if (resp.employees === null || status !== 200) {
        console.err("service indisponible");
      } else {
        this.employees = resp.employees;
      }
      this.loading = false;
    };

    const login = async () => {
      const resp = await axios.post(
        this.URL_API_SERVER + "/admin/employees/login",
        {
          code: this.$route.query.key,
        }
      );
      if (resp.status === 200) {
        if (resp.data.employee === null) {
          this.error = "La clé est inccorecte";
        } else {
          this.user.actions.setUser(resp.data.employee, resp.data.token);
          this.$router.push(resp.data.employee.urlRedirect);
        }
      } else {
        this.error = "Une erreur s'est produite. Code E-P-1";
      }
    };
    this.$nextTick(() => {
      if (typeof this.$route.query.key === "undefined") {
        fetchData();
      } else {
        login();
      }
    });
  },
  methods: {
    async submit() {
      if (!this.loading) {
        this.loading = true;
        this.error = null;
        try {
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/employees/login",
            {
              employee: this.employeeSelected,
              code: this.code,
            }
          );
          if (resp.status === 200) {
            if (resp.data.employee === null || resp.data.result === "nok") {
              this.error = "L'identifiant ou le code est inccorect";
            } else {
              this.user.actions.setUser(resp.data.employee, resp.data.token);
              this.$router.push(resp.data.employee.urlRedirect);
            }
          } else {
            this.error = "Une erreur s'est produite. Code E-P-1";
          }
        } catch (err) {
          if (
            typeof err.response !== "undefined" &&
            err.response.status === 400
          ) {
            this.error = err.response.data.errors.map((e) => e.msg).join("\n");
          } else {
            console.error(err);
          }
        }
        this.loading = false;
      }
    },
  },
};
</script>
