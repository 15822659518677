import { createApp, nextTick } from "vue";
import App from "./App.vue";

import { createRouter, createWebHashHistory } from "vue-router";
import VueHtmlToPaper from "./components/VueHtmlToPaper";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

// Assets
import "./scss/main.scss";

// Parts
import Config from "./pages/Config.vue";
import Security from "./pages/Security.vue";
import Formulas from "./pages/Formulas.vue";
import Formula from "./pages/Formula.vue";
import Products from "./pages/Products.vue";
import Product from "./pages/Product.vue";
import Familys from "./pages/Familys.vue";
import Family from "./pages/Family.vue";
import Index from "./pages/Index.vue";
import Events from "./pages/Events.vue";
import Bookings from "./pages/Bookings.vue";
import Booking from "./pages/Booking.vue";
import Admission from "./pages/Admission.vue";
import Admissions from "./pages/Admissions.vue";
import Closing from "./pages/Closing.vue";
import Closings from "./pages/Closings.vue";
import Kiosks from "./pages/Kiosks.vue";
import Bills from "./pages/Bills.vue";
import Bill from "./pages/Bill.vue";
import Entries from "./pages/Entries.vue";
import Export from "./pages/Export.vue";
import PosDesk from "./pages/PosDesk.vue";
import PosChoose from "./pages/PosChoose.vue";
import Employees from "./pages/Employees.vue";
import Employee from "./pages/Employee.vue";
import Login from "./pages/Login.vue";
import Sale from "./pages/Sale.vue";
import Menu from "./pages/Menu.vue";

// Icons
import PlusIcon from "vue-material-design-icons/Plus.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import UploadIcon from "vue-material-design-icons/Upload.vue";
import EyeIcon from "vue-material-design-icons/Eye.vue";
import EyeOffIcon from "vue-material-design-icons/EyeOff.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import SearchIcon from "vue-material-design-icons/Magnify.vue";
import KioskIcon from "vue-material-design-icons/CellphoneScreenshot.vue";
import CheckIcon from "vue-material-design-icons/Check.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import CreditCardIcon from "vue-material-design-icons/CreditCard.vue";
import CashIcon from "vue-material-design-icons/Cash.vue";
import PrinterIcon from "vue-material-design-icons/Printer.vue";
import CashRegister from "vue-material-design-icons/CashRegister.vue";

const routes = [
  { path: "/", component: Index },
  { path: "/securite", component: Security },
  { path: "/evenements", component: Events },
  { path: "/config", component: Config },
  { path: "/reservations", component: Bookings },
  { path: "/reservations/:id", component: Booking, name: "booking" },
  { path: "/comptoir", component: PosDesk },
  { path: "/employes", component: Employees },
  { path: "/employe/:id", component: Employee, name: "employee" },
  { path: "/connexion", component: Login, name: "login" },
  { path: "/formules", component: Formulas },
  { path: "/formule/:id", component: Formula, name: "formula" },
  { path: "/produits", component: Products },
  {
    path: "/produit/:id",
    component: Product,
    name: "product",
    meta: { reuse: false },
  },
  { path: "/clotures", component: Closings },
  {
    path: "/cloture/:id",
    component: Closing,
    name: "closing",
    meta: { reuse: false },
  },
  { path: "/familles", component: Familys },
  { path: "/famille/:id", component: Family, name: "family" },
  { path: "/entree/:id", component: Admission, name: "admission" },
  { path: "/entrees", component: Admissions },
  { path: "/kiosques", component: Kiosks },
  { path: "/tickets", component: Bills },
  {
    path: "/ticket/:id",
    component: Bill,
    name: "ticket",
    meta: { reuse: false },
  },
  { path: "/billetterie", component: Entries },
  { path: "/vente", component: Sale, meta: { title: "Caisse" } },
  {
    path: "/choixcaisse",
    component: PosChoose,
    name: "PosChoose",
    meta: { title: "Choix caisse" },
  },
  { path: "/export", component: Export },
  { path: "/carte", component: Menu },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
});
const DEFAULT_TITLE = "ENAS - Caisses club";
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title ? "ENAS - " + to.meta.title : DEFAULT_TITLE;
  });
});

const app = createApp(App);
app.use(router);
app.config.globalProperties.globalKey = 0;
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.reuse === false)) {
    app.config.globalProperties.globalKey++;
  } else {
    // TODO: A maméliorer
    app.config.globalProperties.globalKey++;
  }
  next();
});

if (process.env.NODE_ENV === "production") {
  app.config.globalProperties.URL_API_SERVER =
    "https://excalibur.serveurs.enas.fr";
} else {
  app.config.globalProperties.URL_API_SERVER = "http://localhost:3000";
}

app.component("plus-icon", PlusIcon);
app.component("delete-icon", DeleteIcon);
app.component("upload-icon", UploadIcon);
app.component("eye-off-icon", EyeOffIcon);
app.component("eye-icon", EyeIcon);
app.component("pencil-icon", PencilIcon);
app.component("search-icon", SearchIcon);
app.component("kiosk-icon", KioskIcon);
app.component("close-icon", CloseIcon);
app.component("check-icon", CheckIcon);
app.component("credit-card-icon", CreditCardIcon);
app.component("cash-icon", CashIcon);
app.component("printer-icon", PrinterIcon);
app.component("cash-register-icon", CashRegister);

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

app.use(VueHtmlToPaper, options);
app.use(Toast, { position: POSITION.BOTTOM_CENTER });
app.mount("#app");
