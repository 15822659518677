<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Billetterie</li>
              <li>Rapport</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Générer un rapport de billetterie</h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Début</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input class="input" type="date" v-model="dStart" />
                </p>
              </div>
              <div class="field">
                <p class="control">
                  <input class="input" type="time" v-model="tStart" />
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Fin</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input class="input" type="date" v-model="dEnd" />
                </p>
              </div>
              <div class="field">
                <p class="control">
                  <input class="input" type="time" v-model="tEnd" />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Générer</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Printable v-if="data !== null">
        <h2>Rapport de billetterie</h2>
        <h3>
          du {{ new Date(dStart).toLocaleDateString() }} à {{ tStart }} au
          {{ new Date(dEnd).toLocaleDateString() }} à {{ tEnd }}
        </h3>
        <ul>
          <li v-for="e in data.entries" v-bind:key="e._id">
            {{ new Date(e.date).toLocaleDateString() }}
            {{ new Date(e.date).toLocaleTimeString() }}
            Prix payé :
            {{
              new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(e.price)
            }}
            Catégorie : {{ e.category }} Numéro du billet : {{ e.number }}
          </li>
        </ul>
      </Printable>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Printable from "./../components/Printable.vue";
export default {
  data() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return {
      loading: false,
      data: null,
      error: null,
      tStart: "08:00",
      tEnd: "08:00",
      dStart: today.toISOString().substring(0, 10),
      dEnd: tomorrow.toISOString().substring(0, 10),
    };
  },
  components: { Printable },
  created() {},
  methods: {
    async submit() {
      if (!this.loading) {
        this.loading = true;
        try {
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/entries/report",
            {
              dStart: this.dStart,
              hStart: parseInt(this.tStart.split(":")[0]),
              mStart: parseInt(this.tStart.split(":")[1]),
              dEnd: this.dEnd,
              hEnd: parseInt(this.tEnd.split(":")[0]),
              mEnd: parseInt(this.tEnd.split(":")[1]),
            }
          );
          if (resp.status === 200) {
            //this.$router.push({ path: "/formules" });
            this.data = {};
            this.data.entries = resp.data.entries;
          } else {
            // TODO: Handle error
          }
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
        this.loading = false;
      }
    },
  },
};
</script>
