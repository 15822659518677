<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Formules</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <router-link to="/formule/ajouter" class="button is-primary">
                <span class="icon"><plus-icon /></span>
                <span>Ajouter</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card has-table" v-if="formulas.length >= 1">
        <div class="card-content">
          <div class="b-table has-pagination">
            <div class="table-wrapper has-mobile-cards">
              <table
                class="table is-fullwidth is-striped is-hoverable is-fullwidth"
              >
                <thead>
                  <tr>
                    <th class="is-checkbox-cell">
                      <label class="b-checkbox checkbox">
                        <input type="checkbox" value="false" />
                        <span class="check"></span>
                      </label>
                    </th>
                    <th>Nom de la formule</th>
                    <th>Prix</th>
                    <th>Crédit</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="formula in formulas" v-bind:key="formula._id">
                    <td class="is-checkbox-cell">
                      <label class="b-checkbox checkbox">
                        <input type="checkbox" value="false" />
                        <span class="check"></span>
                      </label>
                    </td>

                    <td data-label="Nom">{{ formula.name }}</td>

                    <td data-label="Prix">
                      {{
                        new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(formula.price)
                      }}
                    </td>
                    <td data-label="Crédit">
                      {{
                        new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(formula.credit)
                      }}
                    </td>
                    <td class="is-actions-cell">
                      <div class="buttons is-right">
                        <router-link
                          :to="{ name: 'formula', params: { id: formula._id } }"
                        >
                          <button
                            class="button is-small is-primary"
                            type="button"
                          >
                            <span class="icon"><pencil-icon /></span></button
                        ></router-link>
                        <StateButton
                          :id="formula._id"
                          url="/admin/formulas/"
                          v-model="formula.enabled"
                        />
                        <DeleteButton @confirm="removeFormula(formula._id)" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card has-table" v-if="formulas.length === 0">
        <div class="card-content">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <span class="icon is-large"
                  ><i class="mdi mdi-emoticon-sad mdi-48px"></i
                ></span>
              </p>
              <p v-if="!loading">Aucune formule</p>
              <p v-else>Chargement en cours...</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import DeleteButton from "../components/DeleteButton.vue";
import StateButton from "../components/StateButton.vue";

export default {
  data() {
    return {
      loading: true,
      formulas: [],
    };
  },
  methods: {
    async removeFormula(id) {
      const resp = await axios.post(
        this.URL_API_SERVER + "/admin/formulas/remove",
        {
          id,
        }
      );
      if (resp.status === 200 && resp.data.result === "ok") {
        this.$router.go();
      }
    },
  },
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/restricted/formulas/all",
        { params: { includeDisabled: true } }
      );
      this.loading = false;
      this.formulas = resp.formulas;
    };

    fetchData();
  },

  components: { DeleteButton, StateButton },
};
</script>
