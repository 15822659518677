<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Carte</li>
            </ul>
          </div>
        </div>
        <div class="level-right"></div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card has-table" v-if="products.length >= 1">
        <Printable ref="printable" :header="true">
          <div
            v-for="family in [...familys]
              .sort((a, b) => a.order - b.order)
              .filter(
                (f) =>
                  products.filter((e) => e.family._id === f._id).length >= 1
              )"
            v-bind:key="family"
            class="m-1"
          >
            <h2 class="title is-3" style="margin-bottom: 0; padding-bottom: 0">
              {{ family.name }}
            </h2>
            <div>
              <table class="table" style="font-size: 14px">
                <tr
                  v-for="product in products
                    .filter((e) => e.family._id === family._id)
                    .sort((a, b) => a.name.localeCompare(b.name))"
                  v-bind:key="product"
                >
                  <td>
                    <b style="margin: 0; padding: 0">{{ product.name }}</b>
                  </td>
                  <td
                    v-for="size in product.sizes"
                    v-bind:key="size.name"
                    style="padding-right: 20px"
                  >
                    <i> {{ size.name }}</i>
                    {{
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(
                        size.vats.reduce((acc, cur) => cur.price + acc, 0)
                      )
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </Printable>
      </div>

      <div class="card has-table" v-if="products.length === 0">
        <div class="card-content">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <span class="icon is-large"
                  ><i class="mdi mdi-emoticon-sad mdi-48px"></i
                ></span>
              </p>
              <p v-if="!loading">Aucun produit</p>
              <p v-else>Chargement en cours...</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Printable from "../components/Printable.vue";

export default {
  data() {
    return {
      loading: true,
      products: [],
      familys: [],
    };
  },
  methods: {
    async changeState(product, av) {
      product.availablity[av] = !product.availablity[av];
      await axios.post(this.URL_API_SERVER + "/admin/products/changeState", {
        id: product._id,
        prop: av,
        state: product.availablity[av],
      });
    },
    async removeProduct(id) {
      const resp = await axios.post(
        this.URL_API_SERVER + "/admin/products/remove",
        {
          id,
        }
      );
      if (resp.status === 200 && resp.data.result === "ok") {
        this.$router.go();
      }
    },
  },
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/products/all",
        { params: { filter: "pos" } }
      );
      this.loading = false;
      this.products = resp.products;

      const { data: resp2 } = await axios.get(
        this.URL_API_SERVER + "/restricted/familys/all"
      );
      this.loading = false;
      this.familys = resp2.familys;

      this.currentFamily = this.familys.reduce(
        (a, c) => (a.order < c.order ? a : c),
        this.familys[0].order
      )._id;
    };
    fetchData();
  },

  components: { Printable },
};
</script>
