<template>
  <div class="eti_resa" v-if="booking">Réservation</div>
  <div class="eti_head">
    <div class="eti_logo">
      <img
        :src="config.state.config.logo"
        style="max-width: 200px; border: 1px solid #000"
      />
    </div>
  </div>
  <div class="eti_nom">{{ name }}</div>
  <div class="eti_btl">{{ p.name }} {{ p.size }}</div>
  <div class="eti_accompagnements">
    <div class="eti_blc_titre">Accompagnements</div>
    <div class="checked">0.5L</div>
    <div class="checked">0.5L</div>
    <div>0.5L</div>
  </div>
  <div class="eti_eau">
    <div class="eti_blc_titre">Bouteille d'eau</div>
    <div>0.5L</div>
    <div>0.5L</div>
    <div style="visibility: hidden">0.5L</div>
  </div>
  <div class="prev1">L'abus d'alcool est dangereux pour la santé</div>
  <div class="prev2">Soufflez gratuitement à l'entrée</div>
  <div class="eti_date">
    Bouteille conservée jusqu'au
    <b>{{ formatDate(getJ7) }}</b>
  </div>
  <div class="eti_bill">Ticket {{ bill._id }}/{{ bill.docCount }}</div>
</template>
<script>
export default {
  inject: ["config"],
  props: ["name", "p", "bill", "booking"],
  computed: {
    formatDate() {
      return (d) => {
        let da = new Date(d);
        return da.toLocaleDateString("fr-FR");
      };
    },
    getJ7() {
      const now = new Date();
      now.setDate(now.getDate() + 7);
      return now;
    },
  },
};
</script>
