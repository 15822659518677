<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Produits</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <router-link to="/produit/ajouter" class="button is-primary">
                <span class="icon"><plus-icon /></span>
                <span>Ajouter</span>
              </router-link>
              <router-link to="/carte" class="button is-primary">
                <span class="icon"><printer-icon /></span>
                <span>Voir sous forme de carte</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card has-table" v-if="products.length >= 1">
        <div class="card-content">
          <div class="b-table has-pagination">
            <div class="table-wrapper has-mobile-cards">
              <table
                class="table is-fullwidth is-striped is-hoverable is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>Nom du produit</th>
                    <th>Prix</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in products" v-bind:key="product._id">
                    <td data-label="Nom du produit">{{ product.name }}</td>

                    <td data-label="Prix">
                      {{ product.sizes.length === 1 ? "" : "à partir de" }}
                      {{
                        new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(
                          product.sizes.reduce(
                            (acc, cur) =>
                              cur.vats.reduce(
                                (acc, cur) => cur.price + acc,
                                0
                              ) > acc
                                ? acc
                                : cur.vats.reduce(
                                    (acc, cur) => cur.price + acc,
                                    0
                                  ),
                            product.sizes[0].vats.reduce(
                              (acc, cur) => cur.price + acc,
                              0
                            )
                          )
                        )
                      }}
                    </td>
                    <td class="is-actions-cell">
                      <div class="buttons is-right">
                        <router-link
                          :to="{ name: 'product', params: { id: product._id } }"
                        >
                          <button
                            class="button is-small is-primary"
                            type="button"
                          >
                            <span class="icon"><pencil-icon /></span></button
                        ></router-link>
                        <div
                          class="button is-small"
                          @click="changeState(product, 'kioskBar')"
                        >
                          <span class="icon mr-2">
                            <check-icon
                              v-if="product.availablity.kioskBar" /><close-icon
                              v-else /></span
                          >Dispo bornes bar
                        </div>
                        <div
                          class="button is-small"
                          @click="changeState(product, 'kioskEntry')"
                        >
                          <span class="icon mr-2">
                            <check-icon
                              v-if="
                                product.availablity.kioskEntry
                              " /><close-icon v-else /></span
                          >Dispo bornes entrées
                        </div>
                        <div
                          class="button is-small"
                          @click="changeState(product, 'pos')"
                        >
                          <span class="icon mr-2">
                            <check-icon
                              v-if="product.availablity.pos" /><close-icon
                              v-else /></span
                          >Dispo caisses
                        </div>
                        <DeleteButton @confirm="removeProduct(product._id)" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card has-table" v-if="products.length === 0">
        <div class="card-content">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <span class="icon is-large"
                  ><i class="mdi mdi-emoticon-sad mdi-48px"></i
                ></span>
              </p>
              <p v-if="!loading">Aucun produit</p>
              <p v-else>Chargement en cours...</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import DeleteButton from "../components/DeleteButton.vue";

export default {
  data() {
    return {
      loading: true,
      products: [],
    };
  },
  methods: {
    async changeState(product, av) {
      product.availablity[av] = !product.availablity[av];
      await axios.post(this.URL_API_SERVER + "/admin/products/changeState", {
        id: product._id,
        prop: av,
        state: product.availablity[av],
      });
    },
    async removeProduct(id) {
      const resp = await axios.post(
        this.URL_API_SERVER + "/admin/products/remove",
        {
          id,
        }
      );
      if (resp.status === 200 && resp.data.result === "ok") {
        this.$router.go();
      }
    },
  },
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/products/all",
        { params: { includeDisabled: true } }
      );
      this.loading = false;
      this.products = resp.products;
      this.products.sort((a, b) => a.name.localeCompare(b.name));
    };

    fetchData();
  },

  components: { DeleteButton },
};
</script>
