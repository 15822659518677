<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Comptoir</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content" v-if="mode === null">
          <div class="is-size-3">Quel mode de fonctionnement voulez vous ?</div>
          <div class="columns">
            <div class="column p-5" @click="mode = 'entries'">
              <section class="hero is-primary">
                <div class="hero-body">
                  <p class="title">Entrée</p>
                </div>
              </section>
            </div>
            <div class="column p-5" @click="mode = 'entriesPhone'">
              <section class="hero is-primary">
                <div class="hero-body">
                  <p class="title">Entrée mobile</p>
                </div>
              </section>
            </div>
            <div class="column p-5" @click="mode = 'credits'">
              <section class="hero is-primary">
                <div class="hero-body">
                  <p class="title">Comptoir</p>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div v-else-if="mode === 'entriesPhone'">
          <div class="has-text-centered">
            <select v-model="camera" class="select">
              <option v-for="c in cameras" :value="c.id" v-bind:key="c.id">
                {{ c.label }}
              </option></select
            ><br />
            <button @click="start()" class="button is-large mt-3">
              Démarrer
            </button>
          </div>
          <div id="reader"></div>
        </div>
        <div class="card-content" v-else>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <b>Scan</b>
            </div>
            <div class="field-body">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input
                    type="text"
                    class="input"
                    @keyup.enter="submitScan"
                    v-model="scan"
                    ref="inputScanEntry"
                    placeholder="utilisez le scanner"
                    autofocus
                  />
                </div>
                <div class="control">
                  <a
                    :class="`button is-info ${loading ? 'is-loading' : ''}`"
                    @click="submitScan"
                  >
                    Valider
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered">
            <img
              :src="config.state.config.logo"
              style="width: 80%; margin-top: 4rem; filter: invert(1)"
            />
          </div>
        </div>
        <div v-if="mode == 'credits'">
          <ListenPrint />
        </div>

        <div>
          <Printable
            v-if="products.length > 0"
            :header="false"
            :hidePrintButton="true"
            ref="printablePosDesk"
          >
            <div
              class="eti"
              v-for="p in products"
              v-bind:key="`${p.key}-${bill._id}`"
            >
              <BottleSticker
                :bill="bill"
                :p="p"
                :name="name"
                :booking="false"
              />
            </div>
          </Printable>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Printable from "../components/Printable.vue";
import { Html5Qrcode } from "html5-qrcode";
import ListenPrint from "../components/ListenPrint.vue";
import { useToast } from "vue-toastification";
import BottleSticker from "../components/BottleSticker.vue";
import { ref } from "vue";

export default {
  setup() {
    const printablePosDesk = ref(null);
    const inputScanEntry = ref(null);
    return { printablePosDesk, inputScanEntry };
  },
  data() {
    return {
      loading: false,
      scan: "",
      products: [],
      name: "",
      mode: null,
      result: null,
      user: null,
      bill: null,
    };
  },
  computed: {
    formatDateT() {
      return (d) => {
        let da = new Date(d);
        return (
          da.toLocaleDateString("fr-FR") + " " + da.toLocaleTimeString("fr-FR")
        );
      };
    },
    formatDate() {
      return (d) => {
        let da = new Date(d);
        return da.toLocaleDateString("fr-FR");
      };
    },
  },
  methods: {
    start() {
      const html5QrCode = new Html5Qrcode(/* element id */ "reader");
      html5QrCode
        .start(
          this.camera,
          {
            fps: 10, // Optional, frame per seconds for qr code scanning
          },
          async (decodedText) => {
            // do something when code is read
            console.log(decodedText);

            html5QrCode
              .stop()
              .then(async () => {
                this.scan = decodedText;
                this.submitScan();
              })
              .catch((err) => {
                console.err(err);
              });
          },
          (errorMessage) => {
            console.log(errorMessage);
          }
        )
        .catch((err) => {
          console.log(err);
        });
    },
    async submitScan() {
      this.loading = true;
      this.result = null;
      this.name = "";
      this.products = [];
      const toast = useToast();
      const priceFormat = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      });
      if (["entries", "entriesPhone"].includes(this.mode)) {
        const { data: resp } = await axios.post(
          this.URL_API_SERVER + "/admin/entries/check",
          { scan: this.scan }
        );
        if (resp.result === "ok") {
          if (resp.entry !== null) {
            let d = new Date(resp.entry.date);
            d.setHours(d.getHours() + 10);
            if (d > new Date()) {
              if (resp.entry.used) {
                toast.error(
                  "Cette entrée a déjà été validée le " +
                    this.formatDateT(resp.entry.dUsed)
                );
              } else {
                toast.success(
                  "Entrée valide (" +
                    resp.entry.category +
                    " " +
                    priceFormat.format(resp.entry.price) +
                    " " +
                    this.formatDateT(resp.entry.date) +
                    " N°" +
                    resp.entry.number +
                    ")"
                );
              }
            } else {
              toast.error("Cette entrée est expirée");
            }
          }
        } else {
          toast.error("Une erreur s'est produite");
        }
      } else {
        const { data: resp } = await axios.post(
          this.URL_API_SERVER + "/admin/credits/check",
          { scan: this.scan }
        );
        const credit = resp.credit[0];

        if (resp.credit.length < 1) {
          toast.error("Cet avoir n'existe pas !");
        } else {
          if (new Date(credit.validity) > new Date()) {
            if (credit.used) {
              toast.error("Crédit déjà utilisé !");
            } else {
              if (
                typeof credit.bill.kioskData.admissionProduct !== "undefined" &&
                credit.bill.kioskData.admissionProduct !== null
              ) {
                toast.success(
                  "Crédit valide (produit sans étiquette) " +
                    priceFormat.format(credit.amount)
                );
              } else {
                // produit avec impression
                toast.success("Crédit valide (bon bouteille) !");
                this.name = credit.bill.kioskData.name;
                this.bill = credit.bill;
                this.products = credit.bill.kioskData.products.map((e, j) => ({
                  key: j,
                  name: e.product.name,
                  size: e.size,
                }));
                this.$nextTick(() => {
                  this.$refs.printablePosDesk.print();
                });
              }
            }
          } else {
            toast.error("Crédit périmé !");
          }
        }
      }

      this.scan = "";
      this.loading = false;

      return true;
    },
  },
  created() {
    if (typeof this.$route.query.type !== "undefined") {
      this.mode = this.$route.query.type;
      this.$nextTick(() => {
        if (this.mode === "entry") {
          console.log("focus");
          this.$refs.inputScanEntry.focus();
        }
        setTimeout(() => this.$refs.inputScanEntry.focus(), 5000);
      });
    }
    Html5Qrcode.getCameras()
      .then((devices) => {
        this.cameras = devices;
        this.camera = devices[0].id;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  inject: ["config"],
  components: { Printable, ListenPrint, BottleSticker },
};
</script>
