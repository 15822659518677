<template>
  <div v-if="showAmount" :class="`modal is-active`">
    <div class="modal-background" @click="showAmount = false"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Choix du montant de paiement</p>
        <button
          class="delete"
          aria-label="close"
          @click="showAmount = false"
        ></button>
      </header>
      <section class="modal-card-body">
        <input
          type="number"
          class="input"
          ref="amount"
          autofocus
          v-model="amount"
          @keypress="amountkp"
          step="0.01"
          min="0"
        />
        <SKeyboard
          v-if="config.state.pos.keyboard"
          @onChange="onChange"
          :input="amount"
        />
      </section>
      <footer class="modal-card-foot is-flex is-flex-direction-row-reverse">
        <div
          v-if="parseFloat(amount) > cart.computed.remaining()"
          class="has-text-danger"
        >
          Montant trop élevé
        </div>
        <div v-else>
          <button class="button is-success" @click="addPay(amount, 'cash')">
            Espèces
          </button>
          <button class="button is-success" @click="addPay(amount, 'cb')">
            CB PC
          </button>
          <button
            class="button is-success mr-2"
            @click="addPay(amount, 'cless')"
          >
            CB SC
          </button>
        </div>
      </footer>
    </div>
  </div>
  <button class="button mr-2" @click="addPay(cart.computed.remaining(), 'cb')">
    <credit-card-icon class="mr-2" /> Payer
    {{ cart.computed.totalPaid() > 0 ? "le reste" : "la totalité" }} en CB PC
  </button>
  <button
    class="button mr-2"
    @click="addPay(cart.computed.remaining(), 'cless')"
  >
    <credit-card-icon class="mr-2" /> Payer
    {{ cart.computed.totalPaid() > 0 ? "le reste" : "la totalité" }} en CB SC
  </button>
  <button
    class="button mr-2"
    @click="addPay(cart.computed.remaining(), 'cash')"
  >
    <cash-icon class="mr-2" />Payer
    {{ cart.computed.totalPaid() > 0 ? "le reste" : "la totalité" }} en espèces
  </button>
  <button class="button mr-2" @click="openShowAmount">
    <pencil-icon class="mr-2" />Payer montant
  </button>
</template>
<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import SKeyboard from "./Keyboard.vue";

export default {
  inject: ["cart", "config"],
  props: ["addProductsToPrint", "setName", "setBill", "clearProductsToPrint"],
  components: { SKeyboard },
  data: () => {
    return { showAmount: false, amount: "" };
  },
  computed: {
    total() {
      return this.cart.computed.total();
    },
  },
  methods: {
    async addPay(amount, method) {
      this.cart.actions.addPayment(method, parseFloat(amount));
      this.showAmount = false;

      if (this.cart.computed.remaining() === 0) {
        await this.saveOrder();
        this.cart.actions.clear();
      }
      if (method === "cash") {
        await axios.post(this.URL_API_SERVER + "/admin/sale/openCashDrawer", {
          pos: this.config.state.pos._id,
        });
      }
    },
    async saveOrder() {
      const { data: respSaveTicket } = await axios.post(
        this.URL_API_SERVER + "/restricted/bills/save",
        {
          kioskData: {},
          lines: this.cart.state.products,
          total: this.cart.computed.total(),
          credit: 0,
          admissionCount: 0,
          payments: this.cart.state.payments,
          kioskName: this.config.state.pos.name,
        }
      );
      const toast = useToast();
      toast.success("Ticket enregistré");
      // TODO : Gérer impression
      this.setName(this.cart.state.name);
      this.setBill({
        docCount: respSaveTicket.docCount,
        _id: respSaveTicket.ticketId,
      });
      this.addProductsToPrint(
        this.cart.state.products
          .filter(
            (p) =>
              p.extra.product.family.printSticker === true &&
              p.extra.family !== "Accompagnement"
          )
          .map((p, k) => ({
            ...p,
            key: k,
          }))
      );
    },
    onChange(input) {
      this.amount = input;
    },
    openShowAmount() {
      this.amount = "";
      this.showAmount = true;
      this.$nextTick(() => {
        this.$refs.amount.focus();
      }, 500);
    },
  },
  setup() {},
};
</script>
