<template>
  <div class="box mt-5">
    <h2 class="mb-1 title is-4">Ticket</h2>
    <div class="mt-4 mb-2">
      <div v-for="(product, k) in cart.state.products" v-bind:key="product">
        <CartLine :allowRemove="allowRemove" :k="k" :product="product" />
      </div>
    </div>
    <div class="">
      <div
        v-for="(p, k) in cart.state.payments"
        class="ticketL"
        v-bind:key="`${p.amount}-${p.method}-${k}`"
      >
        <div>
          {{ formatMethod(p.method) }}
        </div>
        <div>
          {{
            new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(p.amount)
          }}
        </div>
      </div>
    </div>
    <div class="ticketL">
      <div><b>Total</b></div>
      <div>
        {{
          new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(total)
        }}
      </div>
    </div>
  </div>
</template>
<script>
import CartLine from "./CartLine.vue";

export default {
  name: "Cart",
  props: { allowRemove: { default: true, type: Boolean } },
  inject: ["cart", "config"],
  methods: {
    formatMethod(m) {
      switch (m) {
        case "cash":
          return "liquide";
        case "cb":
          return "CB PC";
        case "cless":
          return "CB SC";
        default:
          return "erreur";
      }
    },
  },

  components: { CartLine },
  computed: {
    total() {
      return this.cart.computed.total();
    },
  },
  setup() {},
};
</script>
<style scoped>
.ticketL {
  flex-direction: row;
  display: flex;
}
.ticketL div {
  display: flex;
}
.ticketL div:nth-child(1) {
  flex-grow: 1;
}
</style>
