<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Gestion des bornes</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card mb-3" v-for="k in kiosks" v-bind:key="k._id">
        <div class="card-content">
          <div class="has-mobile-cards">
            <div class="columns is-multiline">
              <div class="column is-6 p-4">
                <div class="is-size-5">{{ k.name }}</div>
                <span class="tag is-success" v-if="k.pinged === 'success'"
                  >Connectée</span
                >
                <span class="tag is-danger" v-if="k.pinged === 'error'"
                  >Déconnectée ou non joignable</span
                >
                <span class="tag is-warning" v-if="k.pinged === 'waiting'"
                  ><span class="loading">Chargement</span></span
                >
                <div class="mt-3" v-if="k.pinged === 'success'">
                  <button
                    class="button is-primary mr-2 mb-2"
                    @click="reload(k)"
                  >
                    Recharger / Ouvrir
                  </button>
                  <button
                    v-if="!k.onlyCard"
                    class="button is-primary mr-2 mb-2"
                    @click="payoutTest(k)"
                  >
                    Rendre 10€
                  </button>
                  <button class="button is-primary mr-2 mb-2" @click="close(k)">
                    Fermer
                  </button>
                  <button
                    class="button is-primary mr-2 mb-2"
                    @click="reboot(k)"
                  >
                    Redémarrer
                  </button>
                  <button class="button is-primary mb-2" @click="shutdown(k)">
                    Eteindre
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card" v-if="kiosks.length === 0">
        <div class="card-content">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <span class="icon is-large"
                  ><i class="mdi mdi-emoticon-sad mdi-48px"></i
                ></span>
              </p>
              <p v-if="!loading">Aucune borne</p>
              <p v-else>Chargement en cours...</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: true,
      kiosks: [],
    };
  },
  methods: {
    async close(kiosk) {
      const { data: resp } = await axios.post(
        this.URL_API_SERVER + "/admin/kiosks/routeAction",
        {
          kiosk: kiosk._id,
          action: "close",
        }
      );
      if (resp.result !== "ok") {
        alert("erreur de communication");
      }
    },
    async payoutTest(kiosk) {
      const { data: resp } = await axios.post(
        this.URL_API_SERVER + "/admin/kiosks/routeAction",
        {
          kiosk: kiosk._id,
          action: "payoutTest",
        }
      );
      if (resp.result !== "ok") {
        alert("erreur de communication");
      }
    },
    async reboot(kiosk) {
      const { data: resp } = await axios.post(
        this.URL_API_SERVER + "/admin/kiosks/routeAction",
        {
          kiosk: kiosk._id,
          action: "reboot",
        }
      );
      if (resp.result !== "ok") {
        alert("erreur de communication");
      }
    },
    async shutdown(kiosk) {
      const { data: resp } = await axios.post(
        this.URL_API_SERVER + "/admin/kiosks/routeAction",
        {
          kiosk: kiosk._id,
          action: "shutdown",
        }
      );
      if (resp.result !== "ok") {
        alert("erreur de communication");
      }
    },
    async ping(kiosk) {
      try {
        const source = axios.CancelToken.source();
        let resp = null;
        setTimeout(() => {
          if (resp === null) {
            source.cancel();
          }
        }, 4000);
        resp = (
          await axios.post(this.URL_API_SERVER + "/admin/kiosks/routeAction", {
            kiosk: kiosk._id,
            action: "ping",
          })
        ).data;
        if (resp.result !== "ok") {
          return "error";
        }
        return "success";
      } catch (err) {
        console.log("err catched");
        return "error";
      }
    },
    async reload(kiosk) {
      const { data: resp } = await axios.post(
        this.URL_API_SERVER + "/admin/kiosks/routeAction",
        {
          kiosk: kiosk._id,
          action: "reload",
        }
      );
      if (resp.result !== "ok") {
        alert("erreur de communication");
      }
    },
  },
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/admin/kiosks/all"
      );

      this.kiosks = resp.kiosks;
      this.kiosks.forEach(async (e) => {
        e.pinged = "waiting";
        e.pinged = await this.ping(e);
      });

      this.loading = false;
    };

    fetchData();
  },

  components: {},
};
</script>
