const VueHtmlToPaper = {
  install(app, options = {}) {
    app.config.globalProperties.$htmlToPaper = (
      el,
      localOptions,
      cb = () => true
    ) => {
      let defaultName = "_blank",
        defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        defaultReplace = true,
        defaultStyles = [];
      let {
        name = defaultName,
        specs = defaultSpecs,
        replace = defaultReplace,
        styles = defaultStyles,
      } = options;

      // If has localOptions
      // TODO: improve logic
      if (localOptions) {
        if (localOptions.name) name = localOptions.name;
        if (localOptions.specs) specs = localOptions.specs;
        if (localOptions.replace) replace = localOptions.replace;
        if (localOptions.styles) styles = localOptions.styles;
      }

      specs = specs.length ? specs.join(",") : "";

      const element = window.document.getElementById(el);

      if (!element) {
        alert(`Element to print #${el} not found!`);
        return;
      }

      const url = "";
      const win = window.open(url, name, specs, replace);

      win.document.write(`
          <html>
            <head>
              <title>${window.document.title}</title>
              <style>${styles}
              
              * {
              box-sizing: border-box;
            }
            
            body {
              font-family: Arial;
            }
            
            .eti {
              padding: 0.1cm;
              width: 62mm;
              height: 98mm;
              margin-bottom:10px;
              text-align: center;
            }
            
            .eti_head {
              background: black;
              text-align: center;
              color: white;
              padding: 5px;
              font-size: 14px;
              margin-top:10px;
              margin-bottom: 6px;
            }
            
            .eti_accompagnements div.eti_blc_titre,
            .eti_eau div.eti_blc_titre {
              vertical-align: 0%;
              display: inline-block;
              width: 2cm;
            }
            .eti_accompagnements div.checked {
              background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 30 30'><path d='M30 0 L0 30 ' stroke='black' stroke-width='1'/><path d='M0 0 L30 30 ' stroke='black' stroke-width='2'/></svg>");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 100% 100%, auto;
            }
            
            .eti_accompagnements,
            .eti_eau {
              font-size: 9px;
              color: grey;
              margin-top: 5px;
            }
            
            .eti_accompagnements div:not(.eti_blc_titre),
            .eti_eau div:not(.eti_blc_titre) {
              width: 30px;
              border: 1px solid black;
              display: inline-block;
              text-align: center;
              padding-top: 10px;
              margin: 0 5px 0 5px;
              height: 30px;
            }
            .eti_logo {
              height:30mm;
            } 
            .eti_logo img {
              max-width: 100%;
              width: 43mm;
              margin:auto 0;
            }

            .eti_resa {
              background: black;
              font-weight: bold;
              text-transform: uppercase;
              padding: 5px 0;
              margin-bottom:-20px;
              color: white;
            }
            
            .eti_nom {
              margin-top: 10px;
              padding: 3px 0 3px 0;
              font-size: 18px;
              font-weight: bold;
              border-top: 1px solid grey;
              border-bottom: 1px solid grey;
            }
            
            .eti_btl {
              margin-top: 5px;
              margin-bottom: 7px;
              font-size: 12px;
            }
            
            .eti_date {
              font-size: 12px;
              margin-top: 0.15cm;
              color: grey;
            }
            .eti_bill {
              font-size: 10px;
              margin-top: 0.15cm;
              color: darkgray;
            }
            
            
            .prev1 {
              background: black;
              color: white;
              margin: 6px 0 5px 0;
              padding: 5px;
            }
            
            .prev2 {
              font-weight: bold;
            }</style>
            </head>
            <body>
              ${element.innerHTML}
            </body>
          </html>
        `);

      setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        win.close();
        cb();
      }, 1000);

      return true;
    };
  },
};

export default VueHtmlToPaper;
