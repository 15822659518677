<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Employé</li>
              <li>
                {{ $route.params.id === "ajouter" ? "Ajout" : "Modification" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">
                {{ $route.params.id === "ajouter" ? "Ajout" : "Modification" }}
                d'un employé
              </h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Nom</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="name"
                    min="1"
                    max="70"
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Code</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input"
                    type="text"
                    :placeholder="
                      $route.params.id === 'ajouter'
                        ? 'La longueur minimale est de 6'
                        : 'Entrez un mot de passe pour le modifier'
                    "
                    pattern="[0-9]{6}"
                    v-model="code"
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Code barre</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input class="input" type="text" v-model="barcode" />
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Droits</label>
            </div>
            <div class="field-body">
              <div class="field">
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.events" />
                  évenements
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.bookings" />
                  réservations
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.products" />
                  produits & familles
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.admissions" />
                  entrées
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.sale" />
                  vente
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.kiosks" />
                  bornes
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.entries" />
                  billetterie
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.bills" />
                  tickets
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.closings" />
                  clôtures
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.config" />
                  configuration
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.posdesk" />
                  comptoir
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.employees" />
                  employés
                </label>
                <label class="checkbox mr-2">
                  <input type="checkbox" v-model="rights.dashboard" />
                  tableau de bord
                </label>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      type="submit"
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Enregistrer</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="error !== null"
                class="notification is-danger mt-2"
                style="white-space: pre"
              >
                {{ error }}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  name: "Formula",
  data() {
    return {
      loading: true,
      error: null,
      rights: {
        events: false,
        bookings: false,
        products: false,
        admissions: false,
        kiosks: false,
        entries: false,
        bills: false,
        closings: false,
        config: false,
        posdesk: false,
        employees: false,
        dashboard: false,
        sale: false,
      },
      name: "",
      barcode: "",
      code: "",
    };
  },
  components: {},
  created() {
    const fetchData = async () => {
      if (this.$route.params.id !== "ajouter") {
        const { data: resp, status } = await axios.get(
          this.URL_API_SERVER + "/admin/employees/get",
          { params: { id: this.$route.params.id } }
        );
        if (resp.employee === null || status !== 200) {
          // TODO: Ajouter un message ?
          this.$router.push("/employes");
        } else {
          this.name = resp.employee.name;
          this.barcode = resp.employee.barcode;
          //  this.code = resp.employee.code;
          this.rights = resp.employee.rights;
        }
      }
      this.loading = false;
    };

    fetchData();
  },
  methods: {
    async submit() {
      if (!this.loading) {
        this.loading = true;
        this.error = null;
        try {
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/employees/update",
            {
              id:
                this.$route.params.id === "ajouter"
                  ? undefined
                  : this.$route.params.id,
              name: this.name,
              barcode: this.barcode,
              code: this.code,
              rights: this.rights,
            }
          );
          if (resp.status === 200) {
            if (resp.data.result === "ok") {
              this.$router.push({ path: "/employes" });
            } else {
              const toast = useToast();
              toast.error(resp.data.error);
            }
          } else {
            this.error = "Une erreur s'est produite. Code E-P-1";
          }
        } catch (err) {
          if (err.response.status === 400) {
            this.error = err.response.data.errors.map((e) => e.msg).join("\n");
          } else {
            console.error(err);
          }
        }
        this.loading = false;
      }
    },
  },
};
</script>
