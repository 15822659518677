<template>
  <main
    :class="`${
      !['login'].includes($route.name)
        ? 'has-aside-left has-aside-mobile-transition has-aside-expanded'
        : ''
    }`"
  >
    <Header v-if="!['login'].includes($route.name)" />
    <section class="section-body">
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'fade'" mode="out-in">
          <keep-alive>
            <component :is="Component" :key="globalKey" />
          </keep-alive>
        </transition>
      </router-view>
    </section>
    <Footer v-if="!['verif', 'verifMobile'].includes($route.name)" />
  </main>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Config from "./stores/config";
import User from "./stores/user";
import Cart from "./stores/cart";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "App",
  components: { Header, Footer },
  watch: {
    $route(to) {
      if (!["login"].includes(to.name) && User.state.user === null) {
        this.$router.push({ name: "login" });
      }
    },
  },
  mounted() {
    if (User.state.token !== null) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + User.state.token.id + ".@." + User.state.token.token;
    }

    const router = this.$router;
    // Si 401, déconnecter l'utilisateur
    axios.interceptors.response.use(undefined, function (error) {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 401) {
        User.actions.logout();
        router.push({ name: "login" });
      }
      if (statusCode === 403) {
        const toast = useToast();
        toast.error(
          "Vous n'avez pas l'autorisation d'utiliser cette fonction."
        );
      }

      return Promise.reject(error);
    });
    const fetchData = async () => {
      let now = new Date();
      now.setHours(now.getHours() - 5);

      if (
        Config.state.lastLoad === null ||
        new Date(Config.state.lastLoad) < now
      ) {
        try {
          const { data: respConfig } = await axios.get(
            this.URL_API_SERVER + "/restricted/config/get"
          );
          Config.actions.setConfig(respConfig.config);
        } catch (err) {
          console.log(err);
        }
      }
    };
    this.$nextTick(() => {
      if (this.$router.currentRoute.value.name !== "login") {
        setTimeout(() => {
          fetchData();
        }, 2000);
      }
    });
  },
  provide() {
    return {
      config: Config,
      user: User,
      cart: Cart,
    };
  },
};
</script>
