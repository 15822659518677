<template>
  <div>
    <div class="card" @click="confirm">
      <div class="card-image has-background-white">
        <div
          style="margin: 0.25rem auto"
          class="is-flex is-justify-content-center is-flex-direction-row"
        >
          <div class="image pl-3 pr-3 is-align-self-center is-relative"></div>
        </div>
      </div>
      <div class="card-content pt-0">
        <div class="media mb-1">
          <div class="media-content">
            <p class="has-text-weight-bold mb-0 pb-0">
              {{ product.name }}
            </p>
          </div>
        </div>

        <div class="content is-flex mb-0 is-size-7">
          {{
            new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(product.price)
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Product",
  props: ["product"],
  inject: ["cart", "config"],

  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    confirm() {
      this.showOptions = false;
      this.cart.actions.addProduct({
        name: this.product.name,
        amountIncl: this.product.price,
        amountExcl:
          this.product.price / (1 + this.config.state.config.vatEntries / 100),
        vats: [
          {
            k: 0,
            price: this.product.price,
            vat: this.config.state.config.vatEntries,
          },
        ],
        qty: 1,
        extra: { admission: this.product, family: "Entrées" },
        options: [],
      });
    },
  },
  setup() {},
};
</script>
