<template>
  <button
    v-if="modelValue"
    :class="`button is-small jb-modal ${loading ? 'is-loading' : ''}`"
    @click="changeState(false)"
    type="button"
  >
    <span class="icon"><eye-icon /></span>
  </button>
  <button
    v-else
    :class="`button is-small jb-modal ${loading ? 'is-loading' : ''}`"
    @click="changeState(true)"
    type="button"
  >
    <span class="icon"><eye-off-icon /></span>
  </button>
</template>
<script>
import axios from "axios";
export default {
  props: ["url", "modelValue", "id"],
  emits: ["update:modelValue"],
  data() {
    return { loading: false };
  },
  methods: {
    async changeState(state) {
      this.loading = true;
      try {
        const resp = await axios.post(
          this.URL_API_SERVER + this.url + "changeState",
          {
            id: this.id,
            state,
          }
        );
        if (resp.status === 200 && resp.data.result === "ok") {
          this.$emit("update:modelValue", state);
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
      }
    },
  },
  setup() {},
};
</script>
