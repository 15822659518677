<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>&Eacute;venements</li>
              <li>Ajouter</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Ajouter un évenement</h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Date</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="select">
                  <select v-model="date">
                    <option
                      v-for="f in dates"
                      :value="f._id"
                      v-bind:key="f._id"
                    >
                      {{ formatDate(f.date) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Image</label>
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="" v-if="image !== null">
                <img
                  :src="image"
                  style="max-width: 200px; border: 1px solid #000"
                />
              </div>
              <div class="field is-grouped" v-if="image !== null">
                <button class="button is-danger" @click="image = null">
                  <span class="icon mt-1 mr-1"><DeleteIcon /></span>
                  Supprimer
                </button>
              </div>
              <div class="field" v-else>
                <div class="field file">
                  <label class="upload control">
                    <a class="button is-primary">
                      <span class="icon"><UploadIcon /></span>
                      <span>Sélectionner</span>
                    </a>
                    <input type="file" @change="handleImage" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      type="submit"
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Enregistrer</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="error !== null"
                class="notification is-danger mt-2"
                style="white-space: pre"
              >
                {{ error }}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UploadIcon from "vue-material-design-icons/Upload.vue";
import DeleteIcon from "vue-material-design-icons/TrashCan.vue";
import { readAndCompressImage } from "browser-image-resizer";

import axios from "axios";
export default {
  data() {
    return {
      image: null,
      loading: true,
      error: null,
      dates: [],
      date: null,
    };
  },
  computed: {
    formatDate() {
      return (d) => {
        let da = new Date(d);
        return da.toLocaleDateString("fr-FR");
      };
    },
  },
  components: { UploadIcon, DeleteIcon },
  created() {
    const fetchData = async () => {
      // Chargement de la liste des dates :
      this.dates = (
        await axios.get(this.URL_API_SERVER + "/admin/events/getDates", {
          params: { limit: 100, filterImageNull: true },
        })
      ).data.events;
      this.loading = false;
    };

    fetchData();
  },
  methods: {
    async submit() {
      if (!this.loading) {
        this.loading = true;
        this.error = null;
        try {
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/events/add",
            {
              image: this.image,
              id: this.date,
            }
          );
          if (resp.status === 200) {
            this.image = null;
            this.date = null;
          } else {
            this.error = "Une erreur s'est produite. Code E-P-1";
          }
        } catch (err) {
          if (err.response.status === 400) {
            this.error = err.response.data.errors.map((e) => e.msg).join("\n");
          } else {
            console.error(err);
          }
        }
        this.loading = false;
      }
    },
    async handleImage() {
      const reader = new FileReader();
      let image = await readAndCompressImage(event.target.files[0], {
        quality: 1,
        maxHeight: 700,
        maxWidth: 700,
      });
      reader.readAsDataURL(image);
      reader.onload = () => {
        this.image = reader.result;
      };
    },
  },
};
</script>
