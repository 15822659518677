<template>
  <button
    class="button is-small is-danger jb-modal"
    @click="showConfirm = true"
    type="button"
  >
    <span class="icon"><delete-icon /></span>
  </button>
  <div :class="`modal ${showConfirm ? 'is-active' : ''}`">
    <div class="modal-background" @click="showConfirm = false"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Confirmez vous la suppression ?</p>
        <button
          class="delete"
          aria-label="close"
          @click="showConfirm = false"
        ></button>
      </header>
      <footer class="modal-card-foot is-flex-direction-row-reverse">
        <button class="button is-danger" @click="$emit('confirm')">
          <span class="icon mt-1 mr-1"><delete-icon /></span>Supprimer
        </button>
        <button class="button" @click="showConfirm = false">Non</button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["confirm"],
  data() {
    return { showConfirm: false };
  },
  setup() {},
};
</script>