<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Employés</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <router-link to="/employe/ajouter" class="button is-primary">
                <span class="icon"><plus-icon /></span>
                <span>Ajouter</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card has-table" v-if="employees.length >= 1">
        <div class="card-content">
          <div class="b-table has-pagination">
            <div class="table-wrapper has-mobile-cards">
              <table
                class="table is-fullwidth is-striped is-hoverable is-fullwidth"
              >
                <thead>
                  <tr>
                    <th class="is-checkbox-cell">
                      <label class="b-checkbox checkbox">
                        <input type="checkbox" value="false" />
                        <span class="check"></span>
                      </label>
                    </th>
                    <th>Nom du vendeur</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="admission in employees" v-bind:key="admission._id">
                    <td class="is-checkbox-cell">
                      <label class="b-checkbox checkbox">
                        <input type="checkbox" value="false" />
                        <span class="check"></span>
                      </label>
                    </td>

                    <td data-label="Nom du vendeur">{{ admission.name }}</td>

                    <td class="is-actions-cell">
                      <div class="buttons is-right">
                        <router-link
                          :to="{
                            name: 'employee',
                            params: { id: admission._id },
                          }"
                        >
                          <button
                            class="button is-small is-primary"
                            type="button"
                          >
                            <span class="icon"><pencil-icon /></span></button
                        ></router-link>

                        <DeleteButton
                          v-if="!(admission.locked || admission.hidden)"
                          @confirm="removeProduct(admission._id)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card has-table" v-if="employees.length === 0">
        <div class="card-content">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <span class="icon is-large"
                  ><i class="mdi mdi-emoticon-sad mdi-48px"></i
                ></span>
              </p>
              <p v-if="!loading">Aucun employé</p>
              <p v-else>Chargement en cours...</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import DeleteButton from "../components/DeleteButton.vue";

export default {
  data() {
    return {
      loading: true,
      employees: [],
    };
  },
  methods: {
    async removeProduct(id) {
      const resp = await axios.post(
        this.URL_API_SERVER + "/admin/employees/remove",
        {
          id,
        }
      );
      if (resp.status === 200 && resp.data.result === "ok") {
        this.$router.go();
      }
    },
  },
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/admin/employees/all",
        { params: { includeDisabled: true } }
      );
      this.loading = false;
      this.employees = resp.employees;
    };

    fetchData();
  },

  components: { DeleteButton },
};
</script>
