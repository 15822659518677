import { reactive, readonly } from "vue";

let state = reactive({
  config:
    localStorage.getItem("config") === null
      ? {}
      : JSON.parse(localStorage.getItem("config")),
  lastLoad: null,
  pos: null,
});

const actions = {
  setConfig: (data) => {
    state.config = data;
    state.lastLoad = new Date();
    localStorage.setItem("config", JSON.stringify(state));
  },
  setPos: (pos) => {
    state.pos = { ...pos };
  },
};

const getHeaderTicket = () => {
  let ticket = "";
  ticket +=
    '<img src="' +
    state.config.logo +
    '" style="max-width:12cm; max-height:3cm; float:right; filter:invert(1); margin-bottom:1cm; margin-top:0cm"/>';
  ticket += '<div class="info"> ';
  ticket += "<h2>" + state.config.company + "</h2>";
  ticket += "" + state.config.address + "<br />";
  ticket += "" + state.config.zipCode + " " + state.config.city + "<br />";
  ticket += "" + state.config.registrationNo + "<br />";
  ticket += "" + state.config.additional1 + "<br />";
  ticket += "" + state.config.additional2 + "<br />";

  if (state.config.testMode) ticket += "<b>MODE ECOLE/TEST</b><br />";
  ticket += "</div><!--End Info-->";
  return ticket;
};

export default { state: readonly(state), actions, getHeaderTicket };
