<template>
  <div @keyup="keyup" tabindex="1" style="outline: none" autofocus>
    <section
      class="section is-main-section"
      style="padding: 0; padding-top: 1.5rem"
      v-if="config.state.pos !== null"
    >
      <div class="card">
        <div class="card-content">
          <div class="columns">
            <div class="column is-9">
              <div class="scrollmenu mb-3">
                <div
                  v-for="family in [...familys].sort(
                    (a, b) => a.order - b.order
                  )"
                  v-bind:key="family._id"
                  class="m-1"
                >
                  <div
                    :class="`button  ${
                      family._id === currentFamily ? 'is-primary' : ''
                    }`"
                    @click="currentFamily = family._id"
                  >
                    {{ family.name }}
                  </div>
                </div>
                <div class="m-1">
                  <div
                    :class="`button  ${
                      '@@entries' === currentFamily ? 'is-primary' : ''
                    }`"
                    @click="currentFamily = '@@entries'"
                  >
                    Entrées
                  </div>
                </div>
              </div>
              <div>
                <div
                  v-if="currentFamily === '@@entries'"
                  class="columns is-multiline is-mobile"
                >
                  <EntryProduct
                    class="column is-quarter-tablet is-quarter-tablet is-half-mobile"
                    v-for="adm in admissions"
                    v-bind:key="adm._id"
                    :product="adm"
                  />
                </div>
                <div v-else class="columns is-multiline is-mobile">
                  <div
                    class="column is-quarter-tablet is-quarter-tablet is-half-mobile"
                    v-for="product in products
                      .filter((e) => e.family._id === currentFamily)
                      .sort((a, b) => a.name.localeCompare(b.name))"
                    v-bind:key="product"
                  >
                    <Product :product="product" />
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-3">
              <Cart />
              <Payment
                v-if="cart.state.products.length >= 1"
                :addProductsToPrint="addProductsToPrint"
                :setName="setName"
                :setBill="setBill"
                :clearProductsToPrint="clearProductsToPrint"
              />
              <div
                v-if="
                  cart.state.products.length >= 1 && !config.state.pos.keyboard
                "
                :class="`${loading ? 'is-loading' : ''} button is-primary`"
                @click="send"
              >
                <printer-icon class="mr-2" /> Impression étiquette produits
              </div>
              <div
                :class="`${loading ? 'is-loading' : ''} button is-primary`"
                @click="openCashDrawer"
              >
                <cash-register-icon class="mr-2" /> Tiroir
              </div>
              <div
                :class="`${loading ? 'is-loading' : ''} button is-primary mt-2`"
                @click="printLast"
              >
                <printer-icon class="mr-2" /> Ticket précédent
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div>
      <Printable
        v-if="productsToPrint.length > 0"
        :header="false"
        :hidePrintButton="true"
        ref="printablePosDesk"
      >
        <div
          class="eti"
          v-for="p in productsToPrint.filter(
            (p) =>
              typeof p.extra === 'undefined' ||
              typeof p.extra.family === 'undefined' ||
              p.extra.family !== 'Accompagnement'
          )"
          v-bind:key="`${p.key}-${bill._id}`"
        >
          <BottleSticker :bill="bill" :p="p" :name="name" :booking="false" />
        </div>
      </Printable>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Product from "./../components/Product.vue";
import EntryProduct from "./../components/EntryProduct.vue";
import Cart from "./../components/Cart.vue";
import Payment from "./../components/Payment.vue";
import { useToast } from "vue-toastification";
import Printable from "../components/Printable.vue";
import BottleSticker from "../components/BottleSticker.vue";
import { ref } from "vue";

export default {
  data() {
    return {
      loading: false,
      inputTxt: "",
      familys: [],
      products: [],
      formulas: [],
      admissions: [],
      productsToPrint: [],
      bill: null,
      name: null,
      currentFamily: null,
      printer: null,
    };
  },
  computed: {},
  setup() {
    const printablePosDesk = ref(null);
    return { printablePosDesk };
  },
  methods: {
    async addProductsToPrint(p) {
      this.productsToPrint = p;

      this.$nextTick(() => {
        if (this.productsToPrint.length > 0) {
          this.$refs.printablePosDesk.print();
          this.clearProductsToPrint();
        }
      });
    },
    setName(n) {
      this.name = n;
    },
    clearProductsToPrint() {
      this.productsToPrint = [];
    },
    setBill(b) {
      this.bill = b;
    },
    async printLast() {
      await axios.post(this.URL_API_SERVER + "/admin/sale/print", {
        pos: this.config.state.pos._id,
      });
    },
    async openCashDrawer() {
      await axios.post(this.URL_API_SERVER + "/admin/sale/openCashDrawer", {
        pos: this.config.state.pos._id,
      });
    },
    async keyup(e) {
      if (e.key === "Enter") {
        const toast = useToast();

        this.loading = true;
        this.name = "";
        this.productsToPrint = [];
        const priceFormat = new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        });

        try {
          const { data: resp } = await axios.post(
            this.URL_API_SERVER + "/admin/credits/check",
            { scan: this.inputTxt }
          );
          const credit = resp.credit[0];

          if (resp.credit.length < 1) {
            toast.error("Cet avoir n'existe pas !");
          } else {
            if (new Date(credit.validity) > new Date()) {
              if (credit.used) {
                toast.error("Crédit déjà utilisé !");
              } else {
                if (
                  typeof credit.bill.kioskData.admissionProduct !==
                    "undefined" &&
                  credit.bill.kioskData.admissionProduct !== null
                ) {
                  toast.success(
                    "Crédit valide (produit sans étiquette) " +
                      priceFormat.format(credit.amount)
                  );
                } else {
                  // produit avec impression
                  toast.success("Crédit valide (bon bouteille) !");
                  this.name = credit.bill.kioskData.name;
                  this.bill = credit.bill;
                  this.productsToPrint = credit.bill.kioskData.products.map(
                    (e, j) => ({
                      key: j,
                      name: e.product.name,
                      size: e.size,
                    })
                  );
                  this.$nextTick(() => {
                    if (this.productsToPrint.length > 0) {
                      this.$refs.printablePosDesk.print();
                    }
                  });
                }
              }
            } else {
              toast.error("Crédit périmé !");
            }
          }
        } catch (e) {
          toast.error("Impossible " + e);
        }

        this.loading = false;

        this.inputTxt = "";
      } else {
        console.log(e.code);
        this.inputTxt += e.code
          .replaceAll("Minus", "-")
          .replaceAll("Key", "")
          .replaceAll("Digit", "")
          .replaceAll("ShiftLeft", "")
          .toLowerCase();
      }
    },
    async send() {
      const toast = useToast();
      this.loading = true;
      if (this.cart.computed.remaining() > 0) {
        const resp = await axios.post(
          this.URL_API_SERVER + "/restricted/bills/directPrint",
          {
            ...this.cart.state,
          }
        );
        if (resp.status === 200) {
          this.$router.push({ path: "/vente" });
          //this.cart.actions.clear();
          toast.success("Impression envoyée");
        } else {
          // TODO: Handle error
        }
      } else {
        toast.error("Aucun encaissement rattqché");
      }

      this.loading = false;
    },
  },
  mounted() {
    if (this.config.state.pos === null) {
      this.$router.push({ name: "PosChoose" });
    }
    console.log("mounted");
  },
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/products/all",
        { params: { filter: "pos" } }
      );
      this.products = resp.products;

      const { data: resp2 } = await axios.get(
        this.URL_API_SERVER + "/restricted/familys/all"
      );

      this.familys = resp2.familys;

      // Charger les formules :
      const { data: respFormulas } = await axios.get(
        this.URL_API_SERVER + "/restricted/formulas/all"
      );
      this.formulas = respFormulas.formulas;

      // Charger les entrées :
      const { data: respAdmissions } = await axios.get(
        this.URL_API_SERVER + "/restricted/admissions/all"
      );
      this.admissions = respAdmissions.admissions;

      this.loading = false;
      this.currentFamily = this.familys.reduce(
        (a, c) => (a.order < c.order ? a : c),
        this.familys[0].order
      )._id;
    };
    fetchData();
  },
  inject: ["cart", "config"],
  components: {
    Product,
    Cart,
    Payment,
    EntryProduct,
    Printable,
    BottleSticker,
  },
};
</script>
