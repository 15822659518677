<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Configuration</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Modifier la configuration</h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Mode test / école</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="select">
                  <select v-model="testMode">
                    <option :value="false">Désactivé</option>
                    <option :value="true">Actif</option>
                  </select>
                </p>
                <p class="help">
                  le mode test s'applique au tickets et clôtures uniquement
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">TVA sur les entrées</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="select">
                  <select v-model="vatEntries">
                    <option :value="5.5">5.5%</option>
                    <option :value="20">20%</option>
                  </select>
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Logo</label>
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="" v-if="logo !== null">
                <img
                  :src="logo"
                  style="max-width: 200px; border: 1px solid #000"
                />
              </div>
              <div class="field is-grouped" v-if="logo !== null">
                <button class="button is-danger" @click="logo = null">
                  <span class="icon mt-1 mr-1"><delete-icon /></span>
                  Supprimer
                </button>
              </div>
              <div class="field" v-else>
                <div class="field file">
                  <label class="upload control">
                    <a class="button is-primary">
                      <span class="icon"><upload-icon /></span>
                      <span>Sélectionner</span>
                    </a>
                    <input type="file" @change="handleImage" />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      type="submit"
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Enregistrer</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="error !== null"
                class="notification is-danger mt-2"
                style="white-space: pre"
              >
                {{ error }}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Formula",
  data() {
    return {
      loading: true,
      error: null,
      testMode: null,
      logo: null,
      vatEntries: "",
    };
  },
  components: {},
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/restricted/config/get"
      );

      this.logo = resp.config.logo;
      this.testMode = resp.config.testMode;
      this.vatEntries = resp.config.vatEntries;

      this.loading = false;
    };

    fetchData();
  },
  inject: ["config"],
  methods: {
    handleImage() {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.logo = reader.result;
      };
    },
    async submit() {
      if (!this.loading) {
        this.loading = true;
        this.error = null;
        try {
          let config = {
            logo: this.logo,
            vatEntries: this.vatEntries,
            testMode: this.testMode,
          };
          this.config.actions.setConfig(config);
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/config/update",
            config
          );
          if (resp.status === 200) {
            this.$router.push({ path: "/config" });
          } else {
            this.error = "Une erreur s'est produite. Code E-P-1";
          }
        } catch (err) {
          if (err.response.status === 400) {
            this.error = err.response.data.errors.map((e) => e.msg).join("\n");
          } else {
            console.error(err);
          }
        }
        this.loading = false;
      }
    },
  },
};
</script>
