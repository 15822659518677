<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Clôtures</li>
            </ul>
          </div>
        </div>
        <div class="level-left">
          <div class="level-item">
            <button
              :class="`button is-primary ${
                loadingClosings ? 'is-loading' : ''
              }`"
              @click="closingBalance"
            >
              Réaliser les clôtures
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card has-table" v-if="closings.length >= 1">
        <div class="card-content">
          <div class="b-table has-pagination">
            <div class="table-wrapper has-mobile-cards">
              <table
                class="table is-fullwidth is-striped is-hoverable is-fullwidth"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Nombre de tickets</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="c in closings" v-bind:key="c._id">
                    <td data-label="Date">
                      {{ formatDateT(c.dBegin) }} - {{ formatDateT(c.dEnd) }}
                    </td>

                    <td data-label="Type">
                      {{ formatType(c.type) }}
                    </td>
                    <td data-label="Nombre de tickets">
                      {{ c.billCount }}
                    </td>
                    <td class="is-actions-cell">
                      <div class="buttons is-right">
                        <router-link
                          :to="{ name: 'closing', params: { id: c._id } }"
                        >
                          <button
                            class="button is-small is-primary"
                            type="button"
                          >
                            <span class="icon"><eye-icon /></span></button
                        ></router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card has-table" v-if="closings.length === 0">
        <div class="card-content">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <span class="icon is-large"
                  ><i class="mdi mdi-emoticon-sad mdi-48px"></i
                ></span>
              </p>
              <p v-if="!loading">Aucune clôture n'a encore été effectuée.</p>
              <p v-else>Chargement en cours...</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: true,
      closings: [],
      loadingClosings: false,
    };
  },
  computed: {
    formatDateT() {
      return (d) => {
        let da = new Date(d);
        return (
          da.toLocaleDateString("fr-FR") + " " + da.toLocaleTimeString("fr-FR")
        );
      };
    },
    formatType() {
      return (t) => {
        switch (t) {
          case "daily":
            return "journalière";
          case "monthly":
            return "mensuelle";
          case "yearly":
            return "annuelle";
          case "fiscally":
            return "exercice";
        }
      };
    },
  },
  methods: {
    async fetchData() {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/admin/closings/all"
      );
      this.loading = false;
      this.closings = resp.closings;
    },
    async closingBalance() {
      this.loadingClosings = true;
      const resp = await axios.post(
        this.URL_API_SERVER + "/admin/bills/closingBalance",
        {}
      );
      if (resp.status === 200 && resp.data.result === "ok") {
        this.fetchData();
        this.loadingClosings = false;
      }
    },
  },
  created() {
    this.fetchData();
  },

  components: {},
};
</script>
