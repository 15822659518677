<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Export</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="notification is-success" v-if="success !== null">
            {{ success }}
          </div>
          <div class="notification is-danger" v-if="error !== null">
            {{ error }}
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <b>Stockage disponible</b>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <progress
                    class="progress"
                    :value="100 - parseInt(storage)"
                    max="100"
                  ></progress
                  >{{ storage.toFixed(2) }}%
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <b>Date de début</b>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input type="date" class="input" v-model="dBegin" />
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <b>Date de fin</b>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input type="date" class="input" v-model="dEnd" />
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              Export des tickets / clôtures
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <button
                    :class="`button is-primary ${loading ? 'is-loading' : ''}`"
                    @click="doExport"
                  >
                    Export
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div class="" v-if="logs !== null && logs.length >= 1">
            <h2>Historique des exports</h2>
            <br />
            <div class="b-table has-pagination">
              <div class="table-wrapper has-mobile-cards">
                <table
                  class="table is-fullwidth is-striped is-hoverable is-fullwidth"
                >
                  <thead>
                    <tr>
                      <th class="is-checkbox-cell">
                        <label class="b-checkbox checkbox">
                          <input type="checkbox" value="false" />
                          <span class="check"></span>
                        </label>
                      </th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="c in logs" v-bind:key="c._id">
                      <td class="is-checkbox-cell">
                        <label class="b-checkbox checkbox">
                          <input type="checkbox" value="false" />
                          <span class="check"></span>
                        </label>
                      </td>

                      <td data-label="Date">
                        {{ formatDateT(c.date) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      document: "",
      error: null,
      logs: null,
      success: null,
      storage: 0,
      dBegin: new Date().toISOString().substring(0, 10),
      dEnd: new Date().toISOString().substring(0, 10),
      ina: "",
    };
  },
  computed: {
    formatDateT() {
      return (d) => {
        let da = new Date(d);
        return (
          da.toLocaleDateString("fr-FR") + " " + da.toLocaleTimeString("fr-FR")
        );
      };
    },
    formatDate() {
      return (d) => {
        let da = new Date(d);
        return da.toLocaleDateString("fr-FR");
      };
    },
  },
  methods: {
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    async doExport() {
      this.success = null;
      if (this.loading) return false;
      if (new Date(this.dBegin) > new Date(this.dEnd)) {
        this.error = "La date de début est supérieure à la date de fin";
      } else {
        console.log(
          (new Date(this.dEnd).getTime() - new Date(this.dBegin).getTime()) /
            (1000 * 3600 * 24)
        );
        if (
          Math.ceil(
            (new Date(this.dEnd).getTime() - new Date(this.dBegin).getTime()) /
              (1000 * 3600 * 24)
          ) > 365
        ) {
          this.error = "La période maximale est un an";
        } else {
          this.error = null;
          this.loading = true;
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/config/export",
            { dBegin: this.dBegin, dEnd: this.dEnd }
          );
          if (resp.status === 200 && resp.data.result === "ok") {
            const url = window.URL.createObjectURL(
              this.b64toBlob(resp.data.blob, "application/zip")
            );
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "export.zip";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.success = "Le document a été téléchargé.";
            this.loading = false;
          } else {
            this.error = "Erreur lors du téléchargement";
          }
        }
      }
    },
  },
  created() {
    const fetchData = async () => {
      const { data: resp } = await axios.get(
        this.URL_API_SERVER + "/admin/config/exportLogs"
      );
      this.logs = resp.logs;
      const { data: respS } = await axios.get(
        this.URL_API_SERVER + "/admin/config/storage"
      );
      this.storage = respS.remainingSpace;
      this.loading = false;
    };

    fetchData();
  },

  components: {},
};
</script>
