<template>
  <div>
    <button v-if="!hidePrintButton" @click="print" class="button is-primary">
      Imprimer
    </button>
    <div id="printMe">
      <div class="header" v-if="header" v-html="config.getHeaderTicket()"></div>
      <slot></slot>
    </div>
  </div>
</template>
<style>
.tr {
  display: flex;
}
.tr div {
  flex-grow: 1;
  text-align: right;
}
* {
  box-sizing: border-box;
}

body {
  font-family: Arial;
}

.eti {
  padding: 0.2cm;
  width: 62mm;
  height: 98mm;
  text-align: center;
}

.eti_head {
  background: black;
  text-align: center;
  color: white;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 6px;
}

.eti_accompagnements div.eti_blc_titre,
.eti_eau div.eti_blc_titre {
  vertical-align: 0%;
  display: inline-block;
  width: 2cm;
}

.eti_accompagnements,
.eti_eau {
  font-size: 9px;
  color: grey;
  margin-top: 5px;
}
.eti_accompagnements div.checked {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 30 30'><path d='M30 0 L0 30 ' stroke='black' stroke-width='1'/><path d='M0 0 L30 30 ' stroke='black' stroke-width='2'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}
.eti_accompagnements div:not(.eti_blc_titre),
.eti_eau div:not(.eti_blc_titre) {
  width: 30px;
  border: 1px solid black;
  display: inline-block;
  text-align: center;
  padding-top: 10px;
  margin: 0 5px 0 5px;
  height: 30px;
}
.eti_resa {
  background: black;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 0;
  color: white;
}
.eti_logo {
  height: 30mm;
}
.eti_logo img {
  max-width: 100%;
  margin: auto 0;
  width: 43mm;
}

.eti_nom {
  margin-top: 10px;
  padding: 3px 0 3px 0;
  font-size: 18px;
  font-weight: bold;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}

.eti_btl {
  margin-top: 5px;
  margin-bottom: 7px;
  font-size: 12px;
}

.eti_date {
  font-size: 12px;
  margin-top: 0.15cm;
  color: grey;
}
.eti_bill {
  font-size: 10px;
  margin-top: 0.15cm;
  color: darkgray;
}

.prev1 {
  background: black;
  color: white;
  margin: 6px 0 5px 0;
  padding: 5px;
}

.prev2 {
  font-weight: bold;
}
</style>

<script>
export default {
  data() {
    return {
      output: null,
    };
  },
  inject: ["config"],
  props: {
    hidePrintButton: { type: Boolean, default: false },
    header: { type: Boolean, default: true },
  },
  methods: {
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe", {
        styles:
          ".tr { display: flex;} .tr div {flex-grow: 1;text-align: right;} body {font-size:12px;}",
      });
    },
  },
};
</script>
