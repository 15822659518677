<template>
  <Printable
    v-if="products.length > 0"
    :header="false"
    :hidePrintButton="true"
    ref="printableListenPrint"
  >
    <div
      class="eti"
      v-for="p in products.filter((p) => p.extra.family !== 'Accompagnement')"
      v-bind:key="`${p.key}-${bill._id}`"
    >
      <BottleSticker
        :bill="bill"
        :booking="true"
        :p="{ ...p, name: p.name }"
        :name="name"
      />
    </div>
  </Printable>
</template>
<script>
import axios from "axios";
import Printable from "../components/Printable.vue";
import BottleSticker from "../components/BottleSticker.vue";
import { ref } from "vue";

export default {
  data() {
    return {
      loading: false,
      products: [],
      name: null,
      bill: null,
    };
  },
  components: { Printable, BottleSticker },
  methods: {
    async call() {
      for (;;) {
        await new Promise((r) => setTimeout(r, 4000));
        const resp = await axios.get(
          this.URL_API_SERVER + "/admin/credits/listenForPrint"
        );
        if (resp.status === 200 && resp.data.result === "ok") {
          console.log(resp.data.result);
          if (resp.data.credits.length >= 1) {
            for (let index = 0; index < resp.data.credits.length; index++) {
              const credit = resp.data.credits[index];
              this.products = credit.products;
              this.name = credit.name;
              this.bill = { _id: "Direct " + credit._id };
              this.$nextTick(() => {
                this.$refs.printableListenPrint.print();
                //console.log(this.$refs.printableListenPrint);
                console.log("sent print from listen print");
              });
            }
          } else {
            this.products = [];
            this.name = null;
          }
        }
      }
    },
  },
  setup() {
    const printableListenPrint = ref(null);
    return { printableListenPrint };
  },
  created() {
    console.log("Listen print init");
    this.call();
  },
};
</script>
