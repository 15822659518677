<template>
  <div v-if="showOptions" :class="`modal  is-active`">
    <div class="modal-background" @click="showOptions = false"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ product.name.toUpperCase() }}
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="showOptions = false"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div
            class="column"
            v-bind:key="qty"
            v-for="qty in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20]"
          >
            <div class="button" @click="cart.actions.changeQty(k, qty)">
              {{ qty }}
            </div>
          </div>
        </div>
      </section>

      <footer
        class="modal-card-foot columns is-multiline"
        v-if="cart.computed.totalPaid() === 0"
      >
        <button
          class="button is-danger"
          v-if="allowRemove"
          @click="cart.actions.removeProduct(k)"
        >
          <Delete class="mt-1 mr-1" /> Supprimer
        </button>
        <div class="field has-addons" style="margin-bottom: 0">
          <div class="control">
            <input
              class="input"
              type="number"
              min="1"
              max="100"
              step="1"
              v-model="percentDiscount"
              placeholder="%"
              size="20"
            />
          </div>
          <div
            class="control"
            @click="cart.actions.discountProduct(k, percentDiscount)"
          >
            <a class="button is-info"
              ><SaleOutline class="mt-1 mr-1" /> Remise
            </a>
          </div>
        </div>
        <button
          class="button is-info mr-1"
          @click="cart.actions.discountProduct(k, 100)"
        >
          <Percent class="mt-1 mr-1" /> Offert
        </button>
      </footer>

      <footer class="modal-card-foot columns is-multiline" v-else>
        Aucune action possible sur un ticket encaissé
      </footer>
    </div>
  </div>
  <div class="card" @click="showOptions = true">
    <div class="columns is-mobile is-vcentered is-1">
      <div class="column">
        <div class="card-content">
          <div class="media">
            <div class="media-content" style="align-self: center">
              <div class="content">
                <div class="title is-6 pb-0 is-uppercase">
                  {{ product.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-narrow has-text-centered">
        <div class="p-2 is-size-7">
          <div
            :class="{ linethrought: typeof product.discount !== 'undefined' }"
          >
            {{ product.qty }} x
            {{
              new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(product.amountIncl)
            }}
          </div>
          <div v-if="typeof product.discount !== 'undefined'">
            Remise {{ product.discount }}% <br />
            {{
              new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format((product.amountIncl * (100 - product.discount)) / 100)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Delete from "vue-material-design-icons/Delete.vue";
import SaleOutline from "vue-material-design-icons/SaleOutline.vue";
import Percent from "vue-material-design-icons/Percent.vue";

export default {
  name: "Cart",
  props: {
    allowRemove: { default: true, type: Boolean },
    product: { type: Object },
    k: { type: Number },
  },

  data() {
    return {
      showOptions: false,
      percentDiscount:
        typeof this.product.discount === "undefined"
          ? ""
          : this.product.discount,
    };
  },
  inject: ["cart"],
  components: { Delete, SaleOutline, Percent },

  setup() {},
};
</script>
<style scoped>
.linethrought {
  text-decoration: line-through;
}
</style>
