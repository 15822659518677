<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>{{ config.state.config.company }}</li>
              <li>Tableau de bord</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">Tableau de bord</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section" v-if="data !== null">
      <div class="title is-size-4">Flash toutes caisses</div>
      <div class="is-ancestor tile">
        <div class="tile">
          <card-widget
            class="tile is-child"
            type="is-primary"
            icon="account-multiple"
            :number="data.admissionCount"
            label="Nombre d'entrées vendues"
          />
        </div>
        <div class="tile">
          <card-widget
            class="tile is-child"
            type="is-info"
            icon="cart-outline"
            :number="data.billCount"
            label="Nombre de tickets"
          />
        </div>
        <div class="tile">
          <card-widget
            class="tile is-child"
            type="is-success"
            icon="chart-timeline-variant"
            :number="data.amountExcl.toFixed(2)"
            suffix="€"
            label="Chiffre d'affaire HT"
          />
        </div>
      </div>
    </section>
    <section v-if="data !== null">
      <section
        class="section is-main-section"
        v-for="pos in Object.keys(data.amountByPOS)"
        v-bind:key="pos"
      >
        <div class="title is-size-4">{{ pos }}</div>
        <div class="is-ancestor tile">
          <div class="tile">
            <card-widget
              class="tile is-child"
              type="is-primary"
              icon="account-multiple"
              :number="data.amountByPOS[pos].admissionCount"
              label="Nombre d'entrées"
            />
          </div>
          <div class="tile">
            <card-widget
              class="tile is-child"
              type="is-info"
              icon="cart-outline"
              :number="data.amountByPOS[pos].billCount"
              label="Nombre de tickets"
            />
          </div>
          <div class="tile">
            <card-widget
              class="tile is-child"
              type="is-success"
              icon="chart-timeline-variant"
              :number="data.amountByPOS[pos].amountExcl.toFixed(2)"
              suffix="€"
              label="Chiffre d'affaire HT"
            />
          </div>
        </div>
        <div class="is-ancestor tile">
          <div
            class="tile"
            v-bind:key="method"
            v-for="method in Object.keys(data.amountByPOS[pos].payments)"
          >
            <card-widget
              class="tile is-child"
              type="is-primary"
              icon="account-multiple"
              :number="data.amountByPOS[pos].payments[method]"
              suffix="€"
              :label="formatMethod(method)"
            />
          </div>
        </div>
        <div class="is-ancestor tile">
          <div class="tile">
            <card-widget
              class="tile is-child"
              type="is-primary"
              icon="account-multiple"
              :number="data.amountByPOS[pos].cashOut"
              suffix="€"
              label="Rendu monnaie"
            />
            <card-widget
              class="tile is-child"
              type="is-primary"
              icon="account-multiple"
              :number="data.amountByPOS[pos].amountIncl"
              label="Chiffre d'affaire TTC"
              suffix="€"
            />
            <card-widget
              class="tile is-child"
              type="is-primary"
              icon="account-multiple"
              :number="data.amountByPOS[pos].amountCredits"
              label="Crédit émis"
              suffix="€"
            />
          </div>
        </div>
      </section>
    </section>
    <section class="section is-main-section" v-if="flash !== null">
      <div class="card">
        <div class="card-content">
          <div v-html="flash"></div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4">Contact assistance</p>
            </div>
          </div>

          <div class="content">
            ENAS <br />6 rue claude chappe <br />25300 Pontarlier
            <br />
            axel@enas.fr<br />
            <a href="tel:0033623622508">+33 6 23 62 25 08</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CardWidget from "@/components/CardWidget.vue";
import axios from "axios";

export default {
  name: "Home",
  components: {
    CardWidget,
  },
  inject: ["config", "user"],
  data() {
    return {
      data: null,
      flash: null,
    };
  },
  methods: {
    formatMethod(m) {
      switch (m) {
        case "cash":
          return "Liquide";
        case "cb":
          return "CB PC";
        case "cless":
          return "CB SC";
        default:
          return m;
      }
    },
  },
  created() {
    const fetchData = async () => {
      if (this.user.state.user.rights.dashboard) {
        const { data: resp, status } = await axios.get(
          this.URL_API_SERVER + "/admin/dashboard/get"
        );
        if (resp.data !== null && status === 200) {
          this.data = resp.data;
          this.flash = resp.flash;
        }
      }
    };

    fetchData();
  },
};
</script>
