<template>
  <header class="" style="margin-bottom: 3rem">
    <nav id="navbar-main" class="navbar is-fixed-top">
      <div class="navbar-brand">
        <a class="navbar-item jb-aside-mobile-toggle" @click="toggleMenu">
          <span class="icon"> <BurgerIcon /></span>
        </a>
        <div class="navbar-item has-control">
          <div
            class="control has-text-weight-semibold"
            v-if="config.state.pos !== null"
          >
            {{ config.state.pos.name }}
          </div>
        </div>
      </div>
      <div class="navbar-brand is-right">
        <a
          class="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu"
        >
          <span class="icon"><i class="mdi mdi-dots-vertical"></i></span>
        </a>
      </div>
      <div class="navbar-menu fadeIn animated faster" id="navbar-menu">
        <div class="navbar-end">
          <div
            class="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable"
          >
            <a class="navbar-link is-arrowless">
              <div class="is-user-avatar"><AccountIcon /></div>
              <div class="is-user-name">
                <span>{{
                  user.state.user !== null ? user.state.user.name : ""
                }}</span>
              </div>
              <span class="icon"><i class="mdi mdi-chevron-down"></i></span>
            </a>
            <div class="navbar-dropdown">
              <a class="navbar-item" @click="logout">
                <span class="icon"><Logout /></span>
                <span>Déconnexion</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <aside
      class="aside is-placed-left is-expanded"
      v-if="user.state.user !== null"
    >
      <div class="aside-tools">
        <div class="aside-tools-label">
          <span
            ><b>{{ config.state.config.company }}</b></span
          >
          <span class="ml-2 tag is-warning" v-if="config.state.config.testMode"
            >Mode test</span
          >
        </div>
      </div>
      <div class="menu is-menu-main">
        <p class="menu-label">Général</p>
        <ul class="menu-list">
          <li>
            <router-link class="has-icon" to="/" @click="toggleMenu">
              <span class="icon"><ViewDashboard /></span>
              <span class="menu-item-label">Tableau de bord</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.sale">
            <router-link class="has-icon" to="/choixcaisse" @click="toggleMenu">
              <span class="icon"><PointOfSale /></span>
              <span class="menu-item-label">Vente</span>
            </router-link>
          </li>
        </ul>
        <p class="menu-label">GESTION</p>
        <ul class="menu-list">
          <li v-if="user.state.user.rights.events">
            <router-link class="has-icon" to="/evenements" @click="toggleMenu">
              <span class="icon has-update-mark"> <TableIcon /></span>
              <span class="menu-item-label">&Eacute;venements</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.bookings">
            <router-link
              class="has-icon"
              to="/reservations"
              @click="toggleMenu"
            >
              <span class="icon"> <BookOpenIcon /></span>
              <span class="menu-item-label">Réservations</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.products">
            <router-link class="has-icon" to="/formules" @click="toggleMenu">
              <span class="icon"> <TableIcon /></span>
              <span class="menu-item-label">Formules</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.products">
            <router-link to="/produits" class="has-icon" @click="toggleMenu">
              <span class="icon"><TagTextOutline /></span>
              <span class="menu-item-label">Produits</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.products">
            <router-link to="/familles" class="has-icon" @click="toggleMenu">
              <span class="icon"><FamilyTree /></span>
              <span class="menu-item-label">Familles</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.entries">
            <router-link to="/entrees" class="has-icon" @click="toggleMenu">
              <span class="icon"><TicketConfirmation /></span>
              <span class="menu-item-label">Entrées</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.kiosks">
            <router-link to="/kiosques" class="has-icon" @click="toggleMenu">
              <span class="icon"><CellphoneScreenshot /></span>
              <span class="menu-item-label">Bornes</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.entries">
            <router-link to="/billetterie" class="has-icon" @click="toggleMenu">
              <span class="icon"><AccountSwitch /></span>
              <span class="menu-item-label">Billetterie</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.bills">
            <router-link to="/tickets" class="has-icon" @click="toggleMenu">
              <span class="icon"><ReceiptOutline /></span>
              <span class="menu-item-label">Tickets</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.closings">
            <router-link to="/clotures" class="has-icon" @click="toggleMenu">
              <span class="icon"><ChartBoxOutline /></span>
              <span class="menu-item-label">Clôtures</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.config">
            <router-link to="/config" class="has-icon" @click="toggleMenu">
              <span class="icon"><Cog /></span>
              <span class="menu-item-label">Configuration</span>
            </router-link>
          </li>
          <li>
            <router-link to="/securite" class="has-icon" @click="toggleMenu">
              <span class="icon"><Security /></span>
              <span class="menu-item-label">Validation</span>
            </router-link>
          </li>
          <li>
            <router-link to="/export" class="has-icon" @click="toggleMenu">
              <span class="icon"><Download /></span>
              <span class="menu-item-label">Export</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.posdesk">
            <router-link to="/comptoir" class="has-icon" @click="toggleMenu">
              <span class="icon"><QrcodeScan /></span>
              <span class="menu-item-label">Comptoir</span>
            </router-link>
          </li>
          <li v-if="user.state.user.rights.employees">
            <router-link to="/employes" class="has-icon" @click="toggleMenu">
              <span class="icon"><AccountCheck /></span>
              <span class="menu-item-label">Employés</span>
            </router-link>
          </li>
          <li @click="logout">
            <a href="#" class="has-icon">
              <span class="icon"><Logout /></span>
              <span class="menu-item-label">Déconnexion</span></a
            >
          </li>
        </ul>
      </div>
    </aside>
  </header>
</template>

<script>
import TableIcon from "vue-material-design-icons/Table.vue";
import CellphoneScreenshot from "vue-material-design-icons/CellphoneScreenshot.vue";
import BookOpenIcon from "vue-material-design-icons/BookOpen.vue";
import AccountIcon from "vue-material-design-icons/Account.vue";
import TagTextOutline from "vue-material-design-icons/TagTextOutline.vue";
import FamilyTree from "vue-material-design-icons/FamilyTree.vue";
import BurgerIcon from "vue-material-design-icons/Menu.vue";
import TicketConfirmation from "vue-material-design-icons/TicketConfirmation.vue";
import ReceiptOutline from "vue-material-design-icons/Receipt.vue";
import AccountSwitch from "vue-material-design-icons/AccountSwitch.vue";
import ChartBoxOutline from "vue-material-design-icons/ChartBoxOutline.vue";
import Cog from "vue-material-design-icons/Cog.vue";
import Security from "vue-material-design-icons/Security.vue";
import Download from "vue-material-design-icons/Download.vue";
import QrcodeScan from "vue-material-design-icons/QrcodeScan.vue";
import AccountCheck from "vue-material-design-icons/AccountCheck.vue";
import Logout from "vue-material-design-icons/Logout.vue";
import ViewDashboard from "vue-material-design-icons/ViewDashboard.vue";
import PointOfSale from "vue-material-design-icons/PointOfSale.vue";
export default {
  name: "Header",
  setup() {},
  components: {
    TableIcon,
    BookOpenIcon,
    AccountIcon,
    TagTextOutline,
    FamilyTree,
    BurgerIcon,
    TicketConfirmation,
    CellphoneScreenshot,
    ReceiptOutline,
    AccountSwitch,
    ChartBoxOutline,
    Cog,
    Security,
    Download,
    QrcodeScan,
    AccountCheck,
    Logout,
    PointOfSale,
    ViewDashboard,
  },
  data() {
    return {};
  },
  inject: ["config", "user"],
  methods: {
    logout() {
      this.user.actions.logout();
      this.$router.push({ name: "login" });
    },
    toggleMenu() {
      if (
        document
          .getElementById("html")
          .classList.contains("has-aside-mobile-expanded")
      ) {
        document
          .getElementById("html")
          .classList.remove("has-aside-mobile-expanded");
      } else {
        document
          .getElementById("html")
          .classList.add("has-aside-mobile-expanded");
      }
    },
  },
};
</script>
