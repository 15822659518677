<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Tarif d'entrée</li>
              <li>
                {{ $route.params.id === "ajouter" ? "Ajouter" : "Modifier" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">
                {{ $route.params.id === "ajouter" ? "Ajouter" : "Modifier" }} un
                tarif d'entrée
              </h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Nom</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="name"
                    min="1"
                    max="70"
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Prix</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input"
                    type="number"
                    min="0"
                    step="0.01"
                    v-model="price"
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Heure de début</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input class="input" type="time" v-model="timeStart" />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Heure de fin</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input class="input" type="time" v-model="timeEnd" />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Avoirs</label>
            </div>
            <div class="field-body is-flex-direction-column">
              <div
                class="field is-grouped mb-2"
                v-for="c in credit"
                v-bind:key="c.key"
              >
                <div class="has-addons field">
                  <p class="control is-expanded">
                    <input
                      class="input"
                      type="number"
                      v-model="c.amount"
                      min="0"
                      step=".01"
                      placeholder="Prix"
                    />
                  </p>
                  <p class="control">
                    <a class="button is-static"> € </a>
                  </p>
                </div>
                <div class="has-addons field ml-3">
                  <p class="control">
                    <a class="button is-static"> Texte </a>
                  </p>
                  <p class="control is-expanded">
                    <input
                      class="input"
                      type="text"
                      v-model="c.text"
                      placeholder="Conso, vestiaire..."
                    />
                  </p>
                </div>
                <button @click="removeCredit(c.key)" class="button ml-2">
                  <span class="icon"><delete-icon /></span>
                </button>
              </div>
              <div class="field is-grouped">
                <button class="button" @click="addCredit()">
                  <plus-icon />Ajouter un avoir
                </button>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      type="submit"
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Enregistrer</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="error !== null"
                class="notification is-danger mt-2"
                style="white-space: pre"
              >
                {{ error }}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Formula",
  data() {
    return {
      loading: true,
      error: null,
      name: "",
      price: "",
      timeStart: null,
      timeEnd: null,
      credit: [],
    };
  },
  components: {},
  created() {
    const fetchData = async () => {
      if (this.$route.params.id !== "ajouter") {
        const { data: resp, status } = await axios.get(
          this.URL_API_SERVER + "/restricted/admissions/get",
          { params: { id: this.$route.params.id } }
        );
        if (resp.admission === null || status !== 200) {
          // TODO: Ajouter un message ?
          this.$router.push("/entrees");
        } else {
          this.name = resp.admission.name;
          this.timeStart = resp.admission.timeStart;
          this.timeEnd = resp.admission.timeEnd;
          this.credit = resp.admission.credit;
          this.price = resp.admission.price;
        }
      }
      this.loading = false;
    };

    fetchData();
  },
  methods: {
    async submit() {
      if (!this.loading) {
        this.loading = true;
        this.error = null;
        try {
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/admissions/update",
            {
              id:
                this.$route.params.id === "ajouter"
                  ? undefined
                  : this.$route.params.id,
              name: this.name,
              timeStart: this.timeStart,
              timeEnd: this.timeEnd,
              credit: this.credit,
              price: this.price,
            }
          );
          if (resp.status === 200) {
            this.$router.push({ path: "/entrees" });
          } else {
            this.error = "Une erreur s'est produite. Code E-P-1";
          }
        } catch (err) {
          if (err.response.status === 400) {
            this.error = err.response.data.errors.map((e) => e.msg).join("\n");
          } else {
            console.error(err);
          }
        }
        this.loading = false;
      }
    },

    addCredit() {
      this.credit = [
        ...this.credit,
        {
          key:
            this.credit.reduce(
              (acc, cur) => (acc < cur.key ? cur.key : acc),
              0
            ) + 1,
          amount: 0,
          text: "",
        },
      ];
    },
    removeCredit(k) {
      this.credit = this.credit.filter((e) => e.key !== k);
    },
  },
};
</script>
