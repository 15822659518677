<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Produits</li>
              <li>
                {{ $route.params.id === "ajouter" ? "Ajouter" : "Modifier" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">
                {{ $route.params.id === "ajouter" ? "Ajouter" : "Modifier" }} un
                produit
              </h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Nom</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="name"
                    min="1"
                    max="70"
                  />
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Famille</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="select">
                  <select v-model="family">
                    <option
                      v-for="f in familys"
                      :value="f._id"
                      v-bind:key="f._id"
                    >
                      {{ f.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Conditionnements et tarifs</label>
            </div>
            <div class="field-body is-flex-direction-column">
              <div
                class="field is-grouped-multiline mb-5"
                v-for="size in sizes"
                v-bind:key="size.key"
              >
                <div class="has-addons field mr-2">
                  <p class="control">
                    <a
                      @click="removeSize(size.key)"
                      class="button"
                      v-if="sizes.length > 1"
                    >
                      <span class="icon mt-1 mr-1"><delete-icon /></span>
                    </a>
                  </p>
                  <p class="control is-expanded">
                    <input
                      class="input"
                      type="text"
                      v-model="size.name"
                      placeholder="Nom du conditionnement"
                    />
                  </p>
                </div>
                <div
                  class="has-addons field mr-2"
                  v-for="(sv, ksv) in size.vats"
                  v-bind:key="sv.k"
                >
                  <p class="control">
                    <a class="button is-static"> TVA </a>
                  </p>
                  <p class="control"></p>
                  <p class="select">
                    <select v-model="sv.vat">
                      <option
                        v-for="v in [0, 10, 20]"
                        :value="v"
                        v-bind:key="v"
                      >
                        {{ v }}
                      </option>
                    </select>
                  </p>
                  <p class="control">
                    <input
                      class="input"
                      type="number"
                      size="10"
                      v-model="sv.price"
                      min="0"
                      step=".01"
                      placeholder="Prix"
                    />
                  </p>
                  <p class="control">
                    <a class="button is-static"> €</a>
                  </p>
                  <p class="control" v-if="ksv === size.vats.length - 1">
                    <a
                      @click="
                        size.vats.push({
                          vat: 20,
                          price: 0,
                          k:
                            size.vats.reduce(
                              (maxk, curk) => (curk.k > maxk ? maxk : curk.k),
                              0
                            ) + 1,
                        })
                      "
                      class="button"
                    >
                      +
                    </a>
                  </p>
                  <p class="control" v-else>
                    <a
                      @click="size.vats = size.vats.filter((v) => v.k !== sv.k)"
                      class="button"
                    >
                      <span class="icon mt-1 mr-1"><delete-icon /></span>
                    </a>
                  </p>
                </div>

                <div class="has-addons field">
                  <p class="control">
                    <a class="button is-static"> Total </a>
                  </p>
                  <p class="control">
                    <input
                      class="input"
                      type="number"
                      size="10"
                      :value="
                        size.vats.reduce((acc, cur) => cur.price + acc, 0)
                      "
                      min="0"
                      disabled
                      step=".01"
                    />
                  </p>
                  <p class="control">
                    <a class="button is-static"> € </a>
                  </p>
                </div>
              </div>
              <div class="field is-grouped">
                <button class="button" @click="addSize()">
                  <plus-icon />Ajouter un conditionnement
                </button>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Image</label>
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="" v-if="image !== null">
                <img
                  :src="image"
                  style="max-width: 200px; border: 1px solid #000"
                />
              </div>
              <div class="field is-grouped" v-if="image !== null">
                <button class="button is-danger" @click="image = null">
                  <span class="icon mt-1 mr-1"><delete-icon /></span>
                  Supprimer
                </button>
              </div>
              <div class="field" v-else>
                <div class="field file">
                  <label class="upload control">
                    <a class="button is-primary">
                      <span class="icon"><upload-icon /></span>
                      <span>Sélectionner</span>
                    </a>
                    <input type="file" @change="handleImage" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Disponibilité</label>
            </div>
            <div class="field-body">
              <div class="field">
                <label class="checkbox mr-3">
                  <input type="checkbox" v-model="availablity.kioskEntry" />
                  Bornes formules
                </label>
                <label class="checkbox mr-3">
                  <input type="checkbox" v-model="availablity.kioskBar" />
                  Bornes bar
                </label>
                <label class="checkbox">
                  <input type="checkbox" v-model="availablity.pos" />
                  Caisses
                </label>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body is-flex-direction-column">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      type="submit"
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Enregistrer</span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="error !== null"
                class="notification is-danger mt-2"
                style="white-space: pre"
              >
                {{ error }}
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Formula",
  data() {
    return {
      image: null,
      loading: true,
      error: null,
      name: "",
      family: null,
      availablity: { kioskEntry: true, kioskBar: true, pos: true },
      sizes: [{ key: 1, name: "", vats: [{ vat: 20, price: 0, k: 0 }] }],
      familys: [],
    };
  },
  components: {},
  created() {
    const fetchData = async () => {
      if (this.$route.params.id !== "ajouter") {
        const { data: resp, status } = await axios.get(
          this.URL_API_SERVER + "/products/get",
          { params: { id: this.$route.params.id } }
        );
        if (resp.product === null || status !== 200) {
          // TODO: Ajouter un message ?
          this.$router.push("/produits");
        } else {
          this.image = resp.product.image;
          this.name = resp.product.name;
          this.family = resp.product.family;
          this.sizes = resp.product.sizes;
          this.availablity = resp.product.availablity;
        }
      }

      // Chargement de la liste des famille :
      this.familys = (
        await axios.get(this.URL_API_SERVER + "/restricted/familys/all")
      ).data.familys;
      this.loading = false;
    };

    fetchData();
  },
  methods: {
    async submit() {
      if (!this.loading) {
        this.loading = true;
        this.error = null;
        try {
          console.log(this.availablity);
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/products/update",
            {
              id:
                this.$route.params.id === "ajouter"
                  ? undefined
                  : this.$route.params.id,
              image: this.image,
              name: this.name,
              family: this.family,
              sizes: this.sizes,
              availablity: this.availablity,
            }
          );
          if (resp.status === 200) {
            this.$router.push({ path: "/produits" });
          } else {
            this.error = "Une erreur s'est produite. Code E-P-1";
          }
        } catch (err) {
          if (err.response.status === 400) {
            this.error = err.response.data.errors.map((e) => e.msg).join("\n");
          } else {
            console.error(err);
          }
        }
        this.loading = false;
      }
    },
    handleImage() {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.image = reader.result;
      };
    },
    addSize() {
      this.sizes = [
        ...this.sizes,
        {
          key:
            this.sizes.reduce(
              (acc, cur) => (acc < cur.key ? cur.key : acc),
              this.sizes[0].key
            ) + 1,
          name: "",
          vats: [{ vat: 20, price: 0, k: 0 }],
        },
      ];
    },
    removeSize(k) {
      this.sizes = this.sizes.filter((e) => e.key !== k);
    },
  },
};
</script>
