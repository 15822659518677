<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Familles</li>
              <li>
                {{ $route.params.id === "ajouter" ? "Ajouter" : "Modifier" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">
                {{ $route.params.id === "ajouter" ? "Ajouter" : "Modifier" }}
                une famille
              </h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Nom</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" v-model="name" />
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Ordre</label>
            </div>
            <div class="field-body">
              <div class="field is-expanded">
                <div class="field">
                  <p class="control is-expanded">
                    <input
                      class="input"
                      type="number"
                      min="0"
                      v-model="order"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Impression étiquette</label>
            </div>
            <div class="field-body">
              <div class="control">
                <label class="radio">
                  <input type="radio" :value="true" v-model="printSticker" />
                  Oui
                </label>
                <label class="radio">
                  <input type="radio" :value="false" v-model="printSticker" />
                  Non
                </label>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      type="submit"
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Enregistrer</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      error: null,
      name: "",
      order: "",
      printSticker: true,
    };
  },
  components: {},
  created() {
    const fetchData = async () => {
      if (this.$route.params.id !== "ajouter") {
        const { data: resp, status } = await axios.get(
          this.URL_API_SERVER + "/restricted/familys/get",
          { params: { id: this.$route.params.id } }
        );
        if (resp.family === null || status !== 200) {
          // TODO: Ajouter un message ?
          this.$router.push("/familys");
        } else {
          this.name = resp.family.name;
          this.order = resp.family.order;
          this.printSticker = resp.family.printSticker;
        }
      }
    };

    fetchData();
  },
  methods: {
    async submit() {
      if (!this.loading) {
        this.loading = true;
        try {
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/familys/update",
            {
              id:
                this.$route.params.id === "ajouter"
                  ? undefined
                  : this.$route.params.id,
              name: this.name,
              order: this.order,
              printSticker: this.printSticker,
            }
          );
          if (resp.status === 200) {
            this.$router.push({ path: "/familles" });
          } else {
            // TODO: Handle error
          }
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
        this.loading = false;
      }
    },
  },
};
</script>
