<template>
  <div>
    <section class="section is-title-bar">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Réservations</li>
              <li>
                {{ $route.params.id === "ajouter" ? "Ajouter" : "Modifier" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="hero is-hero-bar">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <h1 class="title">
                {{
                  $route.params.id === "ajouter" ? "Ajouter" : "Modifier"
                }}
                une réservation
              </h1>
            </div>
          </div>
          <div class="level-right" style="display: none">
            <div class="level-item"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="card">
        <div class="card-content">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Date</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="select">
                  <select v-model="date">
                    <option
                      v-for="f in dates"
                      :value="f._id"
                      v-bind:key="f._id"
                    >
                      {{ formatDate(f.date) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Nom</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    v-model="name"
                    @keypress="nameKP"
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Montant</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    ref="amountRef"
                    v-model="price"
                    @keypress="amountKP"
                  />
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Infos complémentaires</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    ref="infosRef"
                    v-model="infos"
                  />
                </p>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
            <div class="field-body">
              <div class="field">
                <div class="field is-grouped">
                  <div class="control">
                    <button
                      type="submit"
                      @click="submit()"
                      :class="`button is-primary ${
                        loading ? 'is-loading' : ''
                      }`"
                    >
                      <span>Enregistrer</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      error: null,
      name: "",
      dates: [],
      date: null,
      price: "",
      infos: "",
    };
  },
  computed: {
    formatDate() {
      return (d) => {
        let da = new Date(d);
        return da.toLocaleDateString("fr-FR");
      };
    },
  },

  components: {},
  created() {
    const fetchData = async () => {
      // Chargement de la liste des dates :
      this.dates = (
        await axios.get(this.URL_API_SERVER + "/admin/events/getDates", {
          params: { limit: 100, filterImageNull: false },
        })
      ).data.events;
      if (this.$route.params.id !== "ajouter") {
        const { data: resp, status } = await axios.get(
          this.URL_API_SERVER + "/admin/bookings/get",
          { params: { id: this.$route.params.id } }
        );
        if (resp.booking === null || status !== 200) {
          // TODO: Ajouter un message ?
          this.$router.push("/reservations");
        } else {
          this.name = resp.booking.name;
          this.price = resp.booking.price;
          this.date = resp.booking.date;
          this.infos = resp.booking.infos;
        }
      }
    };

    fetchData();
  },
  methods: {
    nameKP(e) {
      if (e.keyCode === 13) {
        this.$refs.amountRef.focus();
      }
    },
    amountKP(e) {
      if (e.keyCode === 13) {
        this.$refs.infosRef.focus();
      }
    },
    async submit() {
      if (!this.loading) {
        this.loading = true;
        try {
          const resp = await axios.post(
            this.URL_API_SERVER + "/admin/bookings/update",
            {
              id:
                this.$route.params.id === "ajouter"
                  ? undefined
                  : this.$route.params.id,
              name: this.name,
              price: this.price,
              infos: this.infos,
              date: this.date,
            }
          );
          if (resp.status === 200) {
            this.$router.push({ path: "/reservations" });
          } else {
            // TODO: Handle error
          }
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
        this.loading = false;
      }
    },
  },
};
</script>
