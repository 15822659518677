<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            © {{ new Date().getFullYear() }}, Société ENAS
          </div>
          <div class="level-item"></div>
        </div>
        <div class="level-right">
          <div class="level-item">{{ config.state.config.company }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  inject: ["config"],
  setup() {},
};
</script>
