import { reactive, readonly } from "vue";
import axios from "axios";

let state = reactive(
  localStorage.getItem("user") === null
    ? {
        user: null,
        token: null,
      }
    : JSON.parse(localStorage.getItem("user"))
);
const actions = {
  setUser: (user, token) => {
    state.user = user;
    state.token = token;

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + token.id + ".@." + token.token;
    localStorage.setItem("user", JSON.stringify(state));
  },
  logout: () => {
    state.user = null;
    state.token = null;

    axios.defaults.headers.common["Authorization"] = "";
    localStorage.setItem("user", JSON.stringify(state));
  },
};

export default { state: readonly(state), actions };
